import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  addAutreFraisBegin,
  addAutreFraisSuccess,
  addAutreFraisErr,
  updateAutreFraisBegin,
  updateAutreFraisSuccess,
  updateAutreFraisErr,
  viewAutreFraisBegin,
  viewAutreFraisSuccess,
  viewAutreFraisErr,
  deleteAutreFraisBegin,
  deleteAutreFraisSuccess,
  deleteAutreFraisErr,
  viewInscriptionAutreFraisBegin,
  viewInscriptionAutreFraisSuccess,
  viewInscriptionAutreFraisErr,
  attachAutreFraisBegin,
  attachAutreFraisSuccess,
  attachAutreFraisErr,
  detachAutreFraisBegin,
  detachAutreFraisSuccess,
  detachAutreFraisErr,
} = actions;

const viewAutreFraisData = () => {
  return async (dispatch) => {
    dispatch(viewAutreFraisBegin());
    try {
      const response = await DataService.get('/autre_frais');
      if (response.status === 200) {
        dispatch(viewAutreFraisSuccess(response.data));
      } else {
        dispatch(viewAutreFraisErr('err'));
      }
    } catch (err) {
      dispatch(viewAutreFraisErr('err'));
    }
  };
};

const viewInscriptionAutreFraisData = (id) => {
  return async (dispatch) => {
    dispatch(viewInscriptionAutreFraisBegin());
    try {
      const response = await DataService.get(`/autrefrais/${id}/inscriptions`);
      if (response.status === 200) {
        dispatch(viewInscriptionAutreFraisSuccess(response.data));
      } else {
        dispatch(viewInscriptionAutreFraisErr('err'));
      }
    } catch (err) {
      dispatch(viewInscriptionAutreFraisErr('err'));
    }
  };
};

const addAutreFraisData = (data, callback) => {
  return async (dispatch) => {
    dispatch(addAutreFraisBegin());
    try {
      const response = await DataService.post(`/autre_frais`, data);
      if (response.status === 201) {
        dispatch(addAutreFraisSuccess(response.data));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(addAutreFraisErr('err'));
      }
    } catch (err) {
      dispatch(addAutreFraisErr('err'));
    }
  };
};
const deleteAutreFraisData = (id) => {
  return async (dispatch) => {
    dispatch(deleteAutreFraisBegin());
    try {
      const response = await DataService.delete(`/autre_frais/${id}`, id);
      if (response.status === 200) {
        dispatch(deleteAutreFraisSuccess(id));
        message.success(response.data.message);
      } else {
        dispatch(deleteAutreFraisErr('err'));
      }
    } catch (err) {
      dispatch(deleteAutreFraisErr(err));
    }
  };
};
const updateAutreFraisData = (data, callback) => {
  return async (dispatch) => {
    dispatch(updateAutreFraisBegin());

    try {
      const response = await DataService.put(`/autre_frais/${data.id}`, data);
      if (response.status === 200) {
        dispatch(updateAutreFraisSuccess(response.data));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(updateAutreFraisErr('err'));
      }
    } catch (err) {
      dispatch(updateAutreFraisErr('err'));
    }
  };
};

const attachAutreFraisData = (autreTrancheId, inscriptionId, callback) => {
  return async (dispatch) => {
    dispatch(attachAutreFraisBegin());

    try {
      const response = await DataService.post(`/autrefrais/${autreTrancheId}/inscriptions/attach`, {
        inscription_ids: [inscriptionId],
      });
      if (response.status === 201) {
        dispatch(attachAutreFraisSuccess(response.data));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(attachAutreFraisErr('err'));
      }
    } catch (err) {
      dispatch(attachAutreFraisErr('err'));
    }
  };
};

const detachAutreFraisData = (autreTrancheId, inscriptionId, callback) => {
  return async (dispatch) => {
    dispatch(detachAutreFraisBegin());

    try {
      const response = await DataService.post(`/autrefrais/${autreTrancheId}/inscriptions/detach`, {
        inscription_ids: [inscriptionId],
      });
      if (response.status === 200) {
        dispatch(detachAutreFraisSuccess(response.data));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(detachAutreFraisErr('err'));
      }
    } catch (err) {
      dispatch(detachAutreFraisErr('err'));
    }
  };
};
export {
  viewInscriptionAutreFraisData,
  viewAutreFraisData,
  updateAutreFraisData,
  deleteAutreFraisData,
  addAutreFraisData,
  attachAutreFraisData,
  detachAutreFraisData,
};
