import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  addMatiereBegin,
  addMatiereSuccess,
  addMatiereErr,
  updateMatiereBegin,
  updateMatiereSuccess,
  updateMatiereErr,
  viewMatiereBegin,
  viewMatiereSuccess,
  viewMatiereErr,
  viewMatiereByLevelBegin,
  viewMatiereByLevelSuccess,
  viewMatiereByLevelErr,
  viewSingleMatiereBegin,
  viewSingleMatiereSuccess,
  viewSingleMatiereErr,
  deleteMatiereBegin,
  deleteMatiereSuccess,
  deleteMatiereErr,
} = actions;

const viewMatiereData = () => {
  return async (dispatch) => {
    dispatch(viewMatiereBegin());
    try {
      const response = await DataService.get('/matieres');
      if (response.status === 200) {
        dispatch(viewMatiereSuccess(response.data.payload));
      } else {
        dispatch(viewMatiereErr('err'));
      }
    } catch (err) {
      dispatch(viewMatiereErr('err'));
    }
  };
};

const viewMatiereByLevelData = () => {
  return async (dispatch) => {
    dispatch(viewMatiereByLevelBegin());
    try {
      const response = await DataService.get('/devoires/getMatiereByNiveau');
      if (response.status === 200) {
        dispatch(viewMatiereByLevelSuccess(response.data));
      } else {
        dispatch(viewMatiereByLevelErr('err'));
      }
    } catch (err) {
      dispatch(viewMatiereByLevelErr('err'));
    }
  };
};

const viewSingleMatiereData = (id) => {
  return async (dispatch) => {
    dispatch(viewSingleMatiereBegin());
    try {
      const response = await DataService.get(`/matieres/${id}`);

      if (response.status === 200) {
        dispatch(viewSingleMatiereSuccess(response.data.payload));
      } else {
        dispatch(viewSingleMatiereErr('err'));
      }
    } catch (err) {
      dispatch(viewSingleMatiereErr('err'));
    }
  };
};

const addMatiereData = (data, callback) => {
  return async (dispatch) => {
    dispatch(addMatiereBegin());
    try {
      const response = await DataService.post('/matieres', data);

      console.log(response.data);

      dispatch(addMatiereSuccess(response.data));
      callback();

      message.success(response.data.message);
    } catch (err) {
      dispatch(addMatiereErr('err'));
    }
  };
};
const deleteMatiereData = (id) => {
  return async (dispatch) => {
    dispatch(deleteMatiereBegin());
    try {
      const response = await DataService.delete(`/matieres/${id}`);
      if (response.status === 200) {
        dispatch(deleteMatiereSuccess(id));
        message.success(response.data.message);
      } else {
        dispatch(deleteMatiereErr('err'));
      }
    } catch (err) {
      dispatch(deleteMatiereErr('err'));
    }
  };
};
const updateMatiereData = (id, data, callback) => {
  return async (dispatch) => {
    dispatch(updateMatiereBegin());
    try {
      const response = await DataService.put(`/matieres/${id}`, data);

      if (response.status === 200) {
        dispatch(updateMatiereSuccess(response.data.payload));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(updateMatiereErr('err'));
      }
    } catch (err) {
      dispatch(updateMatiereErr('err'));
    }
  };
};

export {
  viewMatiereData,
  viewMatiereByLevelData,
  viewSingleMatiereData,
  updateMatiereData,
  deleteMatiereData,
  addMatiereData,
};
