import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Modal } from 'antd';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import DataTable from '../../components/table/DataTable';
import { Link } from 'react-router-dom';
import UilEye from '@iconscout/react-unicons/icons/uil-eye';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import UilTrash from '@iconscout/react-unicons/icons/uil-trash-alt';
import { Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { deleteInscriptionData, viewInscriptionData } from '../../redux/inscription/actionCreator';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { viewClasseData } from '../../redux/classe/actionCreator';

function Inscription() {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const { confirm } = Modal;

  const { inscriptions, classes } = useSelector((state) => {
    return {
      classes: state.ClasseReducer.arrayData,
      inscriptions: state.InscriptionReducer.arrayData,
    };
  });
  useEffect(() => {
    dispatch(viewClasseData());
    dispatch(viewInscriptionData());
  }, []);
  const showConfirm = (id) => {
    confirm({
      title: "Voulez-vous supprimer l'inscription ?",
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Supprimer',
      okType: 'danger',
      cancelText: 'Annuler',
      cancelButtonProps: {
        type: 'white',
      },
      onOk() {
        handleDelete(id);
      },
      onCancel() {},
    });
  };
  const handleDelete = useCallback(
    (id) => {
      dispatch(deleteInscriptionData(id));
    },
    [dispatch],
  );

  useEffect(() => {
    const localData = [];
    console.log(inscriptions);

    inscriptions?.map((element) => {
      localData.push({
        ...element,
        paiement: <span className={`status-text blocked`}>payed</span>,
        classe: `${element.classe.niveau} ${element.classe.niveau == 1 ? 'ère' : 'ème'} année ${element.classe.titre} `,
        eleve: (
          <div style={{ fontSize: '.8rem' }}>
            <div>
              {element.prenom} {element.nom}
            </div>
            <div>
              Classe :{' '}
              {`${element.classe.niveau} ${element.classe.niveau == 1 ? 'ère' : 'ème'} ${element.classe.titre} `}
            </div>
            <div>Né le : {element.dateNaiss}</div>
          </div>
        ),
        action: (
          <div className="table-actions">
            <Link className="view" to={`profile/${element.id}/tranches/${element.id}`}>
              <UilEye />
            </Link>
            <Link className="edit" to={`update/${element.id}`}>
              <UilEdit />
            </Link>
            <Link
              className="delete"
              to="#"
              onClick={() => {
                showConfirm(element.id);
              }}
            >
              <UilTrash />
            </Link>
          </div>
        ),
      });
    });
    setData(localData);
  }, [inscriptions]);

  const dataTableColumn = [
    {
      title: 'Nom',
      dataIndex: 'nom',
      key: 'nom',
    },
    {
      title: 'Prénom',
      dataIndex: 'prenom',
      key: 'prenom',
    },
    {
      title: 'Classe',
      dataIndex: 'classe',
      key: 'classe',
    },

    {
      title: 'Date De Naissance',
      dataIndex: 'dateNaiss',
      key: 'dateNaiss',
    },
    {
      title: 'Payed',
      dataIndex: 'paiement',
      key: 'paiement',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '40px',
    },
  ];

  const responsiveDataTableColumn = [
    {
      title: 'Eleve',
      dataIndex: 'eleve',
      key: 'eleve',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '60px',
    },
  ];

  return (
    <>
      <Main>
        <div className="info-section">
          <div>
            <h1 style={{ fontSize: '36px', fontWeight: 700, color: '#FFF', margin: 0 }}>Liste des Élèves</h1>
            <p style={{ color: '#FFF' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laborist. Lorem ipsum
              dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            </p>
          </div>
          <img src={require(`../../static/img/pages/event.svg`).default} />
        </div>
        <Row gutter={25}>
          <Col sm={24} xs={24}>
            <Cards headless>
              <DataTable
                filterOption
                filterOnchange
                buttonName="Ajouter Un Elève"
                tableData={data}
                columns={window.innerWidth < 990 ? responsiveDataTableColumn : dataTableColumn}
              />
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default Inscription;
