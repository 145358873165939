import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Clubs from '../../container/clubs/Clubs';
import Events from '../../container/events/Events';
import ReunionCalendar from '../../container/reunion/Calendar';
import ProductDetails from '../../container/ecommerce/product/overview/ProductDetails';
import Actualite from '../../container/actualite/Actualite';
import AdminActualite from '../../container/pages/adminActualite';
import CantineCalendars from '../../container/cantine/Calendar';
import Exercices from '../../container/exercices/Exercices';
import AddExerciceForm from '../../container/exercices/AddForm';
import UpdateExerciceForm from '../../container/exercices/UpdateForm';
import ClubForm from '../../container/clubs/ClubForm';
import EventForm from '../../container/events/EventForm';
import AbsenceEleve from '../../container/absence_eleve/Calendar';
import Notes from '../../container/notes/Notes';
import Bulletin from '../../container/bulletin/Bulletin';
import Bulletins from '../../container/bulletin/Notes';
import Classes from '../../container/classes/Classes';
import Inscription from '../../container/inscription/Inscription';
import InscriptionForm from '../../container/inscription/InscriptionForm';
import MyProfile from '../../container/inscription/Profile';
import PaymentMethods from '../../container/payment_methods/Payment';
import Profs from '../../container/absence_prof/Profs';
import Personnel from '../../container/personnel/Personnel';
import EventDetails from '../../container/events/details/EventDetails';
import NotFound from '../../container/pages/404';
import UpdateInscriptionForm from '../../container/inscription/UpdateInscriptionForm';
import AddPersonnelForm from '../../container/personnel/AddPersonnelForm';
import UpdatePersonnelForm from '../../container/personnel/UpdatePersonnelForm';
import Charge from '../../container/charge/Charges';
import Emploi from '../../container/emploi/Calendar';
import UpdateClubForm from '../../container/clubs/UpdateClubForm';
import UpdateEventForm from '../../container/events/UpdateEventForm';
import ClubInscription from '../../container/inscription/overview/Club';
import EventInscription from '../../container/inscription/overview/Event';
import TimelineTranches from '../../container/inscription/overview/Tranches';
import TimelineAutreTranches from '../../container/inscription/overview/AutreTranches';
import TypeCharge from '../../container/typeCharge/TypeCharge';
import Tranches from '../../container/payment_methods/Tranches';
import AutreTranches from '../../container/payment_methods/AutreTranches';
import InscriptionsStatistics from '../../container/statistics/InscriptionStatistics';
import MoyenneStatistics from '../../container/statistics/MoyenneStatistics';
import ClubStatistics from '../../container/statistics/ClubStatistics';
import Matiere from '../../container/matiere/Matiere';
import Devoirs from '../../container/devoir/Devoirs';
function PagesRoute() {
  return (
    <Routes>
      <Route index element={<Navigate to={'statistics'} />} />
      <Route path="statistics">
        <Route index element={<Navigate to={'inscriptions'} />} />

        <Route path="inscriptions" element={<InscriptionsStatistics />} />
        <Route path="clubs" element={<ClubStatistics />} />
        <Route path="moyennes" element={<MoyenneStatistics />} />
      </Route>
      <Route path="actualite">
        <Route index element={<AdminActualite />} />
      </Route>
      <Route path="cantine" element={<CantineCalendars />} />
      <Route path="reunion" element={<ReunionCalendar />} />
      <Route path="absenceeleve" element={<AbsenceEleve />} />
      <Route path="emploi" element={<Emploi />} />
      <Route path="clubs">
        <Route index element={<Clubs />} />
        <Route path="club" element={<ProductDetails />} />
        <Route path="edit" element={<UpdateClubForm />} />
        <Route path="form" element={<ClubForm />} />
      </Route>
      <Route path="events">
        <Route index element={<Events />} />
        <Route path="event" element={<EventDetails />} />
        <Route path="form" element={<EventForm />} />
        <Route path="edit" element={<UpdateEventForm />} />
      </Route>
      <Route path="exercices">
        <Route index element={<Exercices />} />
        <Route path="form" element={<AddExerciceForm />} />
        <Route path="update/:id" element={<UpdateExerciceForm />} />
      </Route>
      <Route path="classes" element={<Classes />} />
      <Route path="actualite" element={<Actualite />} />
      <Route path="notes" element={<Notes />} />
      <Route path="bulletin">
        <Route index element={<Bulletins />} />
        <Route path="details/:id" element={<Bulletin />} />
      </Route>
      <Route path="inscription">
        <Route index element={<Inscription />} />
        <Route path="form" element={<InscriptionForm />} />
        <Route path="update/:id" element={<UpdateInscriptionForm />} />
        <Route path="profile/:id" element={<MyProfile />}>
          <Route path="clubs/:id" element={<ClubInscription />} />
          <Route path="events/:id" element={<EventInscription />} />
          <Route path="tranches/:id" element={<TimelineTranches />} />
          <Route path="autretranches/:id" element={<TimelineAutreTranches />} />
        </Route>
      </Route>
      <Route path="paymentmethods" element={<PaymentMethods />}>
        <Route index element={<Navigate to={'tranches'} />} />
        <Route path="tranches" element={<Tranches />} />
        <Route path="autre" element={<AutreTranches />} />
      </Route>
      <Route path="absenceprofs" element={<Profs />} />
      <Route path="personnel">
        <Route index element={<Personnel />} />
        <Route path="form" element={<AddPersonnelForm />} />
        <Route path="update/:id" element={<UpdatePersonnelForm />} />
      </Route>
      <Route path="charge" element={<Charge />} />
      <Route path="typecharge" element={<TypeCharge />} />
      <Route path="matiere" element={<Matiere />} />
      <Route path="devoir" element={<Devoirs />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;
