import { DatePicker, Form, Input, Select, Switch, TimePicker } from 'antd';

import React, { useEffect } from 'react';
import { Button } from '../../components/buttons/buttons';
import { ReunionFormStyleWrap } from '../absence_eleve/Style';

function UserForm({ onHandleAddEvent, userData }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (userData) {
      form.setFieldsValue(userData);
    }
  }, [userData]);

  const reset = () => {};

  const handleSubmit = (values) => {
    if (userData) {
      values.id = userData.id;
    }
    onHandleAddEvent(values);
  };

  return (
    <>
      <ReunionFormStyleWrap>
        <Form form={form} name="addReunion" onFinish={handleSubmit} layout="vertical">
          <div className="edulink-event-form-input">
            <Form.Item
              name="organisation"
              label="Organisation"
              rules={[{ required: true, message: "Nom de L'organisation est obligatoire !" }]}
            >
              <Input placeholder="Choisir le nom de l'organisation" />
            </Form.Item>
          </div>
          <div className="edulink-event-form-input">
            <Form.Item
              name="type"
              label="Type"
              rules={[{ required: true, message: "Type de L'organisation est obligatoire !" }]}
            >
              <Select
                placeholder="Choisir le type de l'organisation"
                options={[
                  {
                    value: '1',
                    label: "jardin d'enfant",
                  },
                  {
                    value: '2',
                    label: 'école',
                  },
                ]}
              />
            </Form.Item>
          </div>
          <div className="edulink-event-form-input">
            <Form.Item
              name="adresse"
              label="Adresse"
              rules={[{ required: true, message: 'Adresse est obligatoire !' }]}
            >
              <Input placeholder="Choisir l'adresse de l'organisation" />
            </Form.Item>
          </div>
          <div className="edulink-event-form-input">
            <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Email est obligatoire !' }]}>
              <Input placeholder="Choisir l'email de l'organisation" />
            </Form.Item>
          </div>
          <div className="edulink-event-form-input">
            <Form.Item
              name="numTel"
              label="Telephone"
              rules={[{ required: true, message: 'Telephone est obligatoire !' }]}
            >
              <Input placeholder="Choisir le numero de telephone" />
            </Form.Item>
          </div>
          <div className="edulink-event-form-input">
            <Form.Item
              name="nbEleve"
              label="Nombre d'éléves"
              rules={[{ required: true, message: 'Telephone est obligatoire !' }]}
            >
              <Input placeholder="Choisir le numero de telephone" />
            </Form.Item>
          </div>
          <Form.Item
            name="status"
            valuePropName="checked"
            label="Active"
            rules={[{ required: true, message: 'Telephone est obligatoire !' }]}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="sms"
            valuePropName="checked"
            label="SMS Active"
            rules={[{ required: true, message: 'Telephone est obligatoire !' }]}
          >
            <Switch />
          </Form.Item>

          <div className="add-event-footer text-right">
            <Button className="ant-btn ant-btn-white" onClick={reset}>
              Reset
            </Button>
            <Button htmlType="submit" className="btn-save" type="primary">
              Enregistrer
            </Button>
          </div>
        </Form>
      </ReunionFormStyleWrap>
    </>
  );
}

export default UserForm;
