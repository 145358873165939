import UilMultiply from '@iconscout/react-unicons/icons/uil-multiply';
import { Col, Form, Input, Modal, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Button } from '../../components/buttons/buttons';
import { useDispatch, useSelector } from 'react-redux';
import { ClassFormStyleWrap } from './style';
import { addDevoirData, updateDevoirData } from '../../redux/devoir/actionCreator';
import { viewMatiereData } from '../../redux/matiere/actionCreator';


function DevoirForm({ open, closeModal, title, data }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewMatiereData());
  }, []);
  const { matieres } = useSelector((state) => {
    return {
      matieres: state.MatiereReducer.arrayData,
    };
  });
  const [form, setForm] = useState({
    nom: '',
    coeff: null,
    niveau: null,
    matiere_id: null,
    duree: null,
  });

  // Populate form on edit
  useEffect(() => {
    if (data) {
      setForm(data);
    }
  }, [data]);

  const handleChange = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSubmit = () => {
    if (data) {
      dispatch(updateDevoirData(data.id, { ...form, duree: `${form.duree}` }, closeModal));
    } else {
      dispatch(addDevoirData(form, closeModal));
    }
  };

  return (
    <Modal
      open={open}
      onCancel={closeModal}
      closable={false}
      footer={null}
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {title}
          <div style={{ display: 'flex', alignItems: 'center', columnGap: '7px', cursor: 'pointer' }}>
            <UilMultiply size={16} onClick={closeModal} />
          </div>
        </div>
      }
    >
      <ClassFormStyleWrap>
        <Form layout="vertical" onFinish={handleSubmit}>
          <Row gutter={30}>
            <Col md={24} xs={24}>
              <Form.Item label="Nom" rules={[{ required: true, message: 'Le nom est obligatoire!' }]}>
                <Input placeholder="Nom" value={form.nom} onChange={(e) => handleChange('nom', e.target.value)} />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item label="Coefficient" rules={[{ required: true, message: 'Le coefficient est obligatoire!' }]}>
                <Input
                  type="number"
                  placeholder="Coefficient"
                  value={form.coeff}
                  onChange={(e) => handleChange('coeff', Number(e.target.value))}
                />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item label="Duree" rules={[{ required: true, message: 'La duree est obligatoire!' }]}>
                <Input
                  placeholder="Duree"
                  value={form.duree}
                  onChange={(e) => handleChange('duree', Number(e.target.value))}
                />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item label="Niveau" rules={[{ required: true, message: 'Le niveau est obligatoire!' }]}>
                <Select
                  placeholder="Choisir le niveau"
                  value={form.niveau}
                  onChange={(value) => handleChange('niveau', value)}
                  options={[
                    { value: 1, label: 'Niveau 1' },
                    { value: 2, label: 'Niveau 2' },
                    { value: 3, label: 'Niveau 3' },
                    { value: 4, label: 'Niveau 4' },
                    { value: 5, label: 'Niveau 5' },
                    { value: 6, label: 'Niveau 6' },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item label="Matiere" rules={[{ required: true, message: 'Le niveau est obligatoire!' }]}>
                <Select
                  placeholder="Choisir la matiere"
                  value={form.matiere_id}
                  onChange={(value) => handleChange('matiere_id', value)}
                  options={
                    matieres && matieres.length > 0
                      ? matieres.map((matiere) => ({ value: matiere.id, label: matiere.nom }))
                      : []
                  }
                />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                <Button key="back" onClick={closeModal}>
                  Annuler
                </Button>
                <Button key="submit" type="primary" onClick={handleSubmit}>
                  Confirmer
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </ClassFormStyleWrap>
    </Modal>
  );
}

export default DevoirForm;
