const actions = {
  VIEW_AUTRE_FRAIS_BEGIN: 'VIEW_AUTRE_FRAIS_BEGIN',
  VIEW_AUTRE_FRAIS_SUCCESS: 'VIEW_AUTRE_FRAIS_SUCCESS',
  VIEW_AUTRE_FRAIS_ERR: 'VIEW_AUTRE_FRAIS_ERR',

  ADD_AUTRE_FRAIS_BEGIN: 'ADD_AUTRE_FRAIS_BEGIN',
  ADD_AUTRE_FRAIS_SUCCESS: 'ADD_AUTRE_FRAIS_SUCCESS',
  ADD_AUTRE_FRAIS_ERR: 'ADD_AUTRE_FRAIS_ERR',

  VIEW_INSCRIPTION_AUTRE_FRAIS_BEGIN: 'VIEW_INSCRIPTION_AUTRE_FRAIS_BEGIN',
  VIEW_INSCRIPTION_AUTRE_FRAIS_SUCCESS: 'VIEW_INSCRIPTION_AUTRE_FRAIS_SUCCESS',
  VIEW_INSCRIPTION_AUTRE_FRAIS_ERR: 'VIEW_INSCRIPTION_AUTRE_FRAIS_ERR',

  ATTACH_AUTRE_FRAIS_BEGIN: 'ATTACH_AUTRE_FRAIS_BEGIN',
  ATTACH_AUTRE_FRAIS_SUCCESS: 'ATTACH_AUTRE_FRAIS_SUCCESS',
  ATTACH_AUTRE_FRAIS_ERR: 'ATTACH_AUTRE_FRAIS_ERR',

  DETACH_AUTRE_FRAIS_BEGIN: 'DETACH_AUTRE_FRAIS_BEGIN',
  DETACH_AUTRE_FRAIS_SUCCESS: 'DETACH_AUTRE_FRAIS_SUCCESS',
  DETACH_AUTRE_FRAIS_ERR: 'DETACH_AUTRE_FRAIS_ERR',

  DELETE_AUTRE_FRAIS_BEGIN: 'DELETE_AUTRE_FRAIS_BEGIN',
  DELETE_AUTRE_FRAIS_SUCCESS: 'DELETE_AUTRE_FRAIS_SUCCESS',
  DELETE_AUTRE_FRAIS_ERR: 'DELETE_AUTRE_FRAIS_ERR',

  UPDATE_AUTRE_FRAIS_BEGIN: 'UPDATE_AUTRE_FRAIS_BEGIN',
  UPDATE_AUTRE_FRAIS_SUCCESS: 'UPDATE_AUTRE_FRAIS_SUCCESS',
  UPDATE_AUTRE_FRAIS_ERR: 'UPDATE_AUTRE_FRAIS_ERR',

  viewAutreFraisBegin: () => {
    return {
      type: actions.VIEW_AUTRE_FRAIS_BEGIN,
    };
  },

  viewAutreFraisSuccess: (data) => {
    return {
      type: actions.VIEW_AUTRE_FRAIS_SUCCESS,
      data,
    };
  },

  viewAutreFraisErr: (err) => {
    return {
      type: actions.VIEW_AUTRE_FRAIS_ERR,
      err,
    };
  },

  updateAutreFraisBegin: () => {
    return {
      type: actions.UPDATE_AUTRE_FRAIS_BEGIN,
    };
  },

  updateAutreFraisSuccess: (data) => {
    return {
      type: actions.UPDATE_AUTRE_FRAIS_SUCCESS,
      data,
    };
  },

  updateAutreFraisErr: (err) => {
    return {
      type: actions.UPDATE_AUTRE_FRAIS_ERR,
      err,
    };
  },
  deleteAutreFraisBegin: () => {
    return {
      type: actions.DELETE_AUTRE_FRAIS_BEGIN,
    };
  },

  deleteAutreFraisSuccess: (data) => {
    return {
      type: actions.DELETE_AUTRE_FRAIS_SUCCESS,
      data,
    };
  },

  deleteAutreFraisErr: (err) => {
    return {
      type: actions.DELETE_AUTRE_FRAIS_ERR,
      err,
    };
  },
  addAutreFraisBegin: () => {
    return {
      type: actions.ADD_AUTRE_FRAIS_BEGIN,
    };
  },

  addAutreFraisSuccess: (data) => {
    return {
      type: actions.ADD_AUTRE_FRAIS_SUCCESS,
      data,
    };
  },

  addAutreFraisErr: (err) => {
    return {
      type: actions.ADD_AUTRE_FRAIS_ERR,
      err,
    };
  },

  viewInscriptionAutreFraisBegin: () => {
    return {
      type: actions.VIEW_INSCRIPTION_AUTRE_FRAIS_BEGIN,
    };
  },

  viewInscriptionAutreFraisSuccess: (data) => {
    return {
      type: actions.VIEW_INSCRIPTION_AUTRE_FRAIS_SUCCESS,
      data,
    };
  },

  viewInscriptionAutreFraisErr: (err) => {
    return {
      type: actions.VIEW_INSCRIPTION_AUTRE_FRAIS_ERR,
      err,
    };
  },
  attachAutreFraisBegin: () => {
    return {
      type: actions.ATTACH_AUTRE_FRAIS_BEGIN,
    };
  },

  attachAutreFraisSuccess: (data) => {
    return {
      type: actions.ATTACH_AUTRE_FRAIS_SUCCESS,
      data,
    };
  },

  attachAutreFraisErr: (err) => {
    return {
      type: actions.ATTACH_AUTRE_FRAIS_ERR,
      err,
    };
  },
  detachAutreFraisBegin: () => {
    return {
      type: actions.DETACH_AUTRE_FRAIS_BEGIN,
    };
  },

  detachAutreFraisSuccess: (data) => {
    return {
      type: actions.DETACH_AUTRE_FRAIS_SUCCESS,
      data,
    };
  },

  detachAutreFraisErr: (err) => {
    return {
      type: actions.DETACH_AUTRE_FRAIS_ERR,
      err,
    };
  },
};

export default actions;
