import { DataService } from '../../config/dataService/dataService';
import { message } from 'antd';
import actions from './actions';
import { viewFactureData } from '../inscription/actionCreator';

const { addPaimentBegin, addPaimentSuccess, addPaimentErr, viewPaimentBegin, viewPaimentSuccess, viewPaimentErr } =
  actions;

const viewPaimentData = () => {
  return async (dispatch) => {
    const paiments = JSON.parse(localStorage.getItem('paiments'));
    dispatch(viewPaimentBegin());
    try {
      dispatch(viewPaimentSuccess(paiments));
    } catch (err) {
      dispatch(viewPaimentErr('err'));
    }
  };
};

const addPaimentData = (data, callback) => {
  return async (dispatch) => {
    dispatch(addPaimentBegin());
    try {
      const response = await DataService.post('/paiment/add', data);
      if (response.status === 201) {
        dispatch(addPaimentSuccess());
        dispatch(viewFactureData(data.etudiant_id));
        callback();
      } else {
        dispatch(addPaimentErr('err'));
      }
    } catch (err) {
      dispatch(addPaimentErr('err'));
    }
  };
};

export { viewPaimentData, addPaimentData };
