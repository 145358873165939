import { message } from 'antd';
import actions from './actions';

const initialState = {
  data: [],
  attached: [],
  loading: false,
  error: null,
};

const {
  VIEW_AUTRE_FRAIS_BEGIN,
  VIEW_AUTRE_FRAIS_SUCCESS,
  VIEW_AUTRE_FRAIS_ERR,

  ADD_AUTRE_FRAIS_BEGIN,
  ADD_AUTRE_FRAIS_SUCCESS,
  ADD_AUTRE_FRAIS_ERR,

  DELETE_AUTRE_FRAIS_BEGIN,
  DELETE_AUTRE_FRAIS_SUCCESS,
  DELETE_AUTRE_FRAIS_ERR,

  UPDATE_AUTRE_FRAIS_BEGIN,
  UPDATE_AUTRE_FRAIS_SUCCESS,
  UPDATE_AUTRE_FRAIS_ERR,

  VIEW_INSCRIPTION_AUTRE_FRAIS_BEGIN,
  VIEW_INSCRIPTION_AUTRE_FRAIS_SUCCESS,
  VIEW_INSCRIPTION_AUTRE_FRAIS_ERR,

  ATTACH_AUTRE_FRAIS_BEGIN,
  ATTACH_AUTRE_FRAIS_SUCCESS,
  ATTACH_AUTRE_FRAIS_ERR,

  DETACH_AUTRE_FRAIS_BEGIN,
  DETACH_AUTRE_FRAIS_SUCCESS,
  DETACH_AUTRE_FRAIS_ERR,
} = actions;

const AutreFraisReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_AUTRE_FRAIS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_AUTRE_FRAIS_SUCCESS:
      const updatedData = state.data.map((item) => (item.id != data.id ? item : data));
      console.log(updatedData);

      return {
        ...state,
        data: updatedData,
        loading: false,
      };
    case UPDATE_AUTRE_FRAIS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_AUTRE_FRAIS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_AUTRE_FRAIS_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case VIEW_AUTRE_FRAIS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_AUTRE_FRAIS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_AUTRE_FRAIS_SUCCESS:
      const arrayData = state.data.filter((item) => item.id !== data);
      return {
        ...state,
        data: arrayData,
        loading: false,
      };
    case DELETE_AUTRE_FRAIS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_AUTRE_FRAIS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_AUTRE_FRAIS_SUCCESS:
      return {
        ...state,
        data: [...state.data, data],
        loading: false,
      };
    case ADD_AUTRE_FRAIS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case VIEW_INSCRIPTION_AUTRE_FRAIS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_INSCRIPTION_AUTRE_FRAIS_SUCCESS:
      return {
        ...state,
        attached: data,
        loading: false,
      };
    case VIEW_INSCRIPTION_AUTRE_FRAIS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ATTACH_AUTRE_FRAIS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ATTACH_AUTRE_FRAIS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ATTACH_AUTRE_FRAIS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case DETACH_AUTRE_FRAIS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DETACH_AUTRE_FRAIS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DETACH_AUTRE_FRAIS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default AutreFraisReducer;
