import { message } from 'antd';
import actions from './actions';

const initialState = {
  arrayData: [],
  arrayDataLevel: [],
  singleData: null,
  loading: false,
  error: null,
};

const {
  VIEW_MATIERE_BEGIN,
  VIEW_MATIERE_SUCCESS,
  VIEW_MATIERE_ERR,

  VIEW_MATIERE_BY_LEVEL_BEGIN,
  VIEW_MATIERE_BY_LEVEL_SUCCESS,
  VIEW_MATIERE_BY_LEVEL_ERR,

  VIEW_SINGLE_MATIERE_BEGIN,
  VIEW_SINGLE_MATIERE_SUCCESS,
  VIEW_SINGLE_MATIERE_ERR,

  ADD_MATIERE_BEGIN,
  ADD_MATIERE_SUCCESS,
  ADD_MATIERE_ERR,

  DELETE_MATIERE_BEGIN,
  DELETE_MATIERE_SUCCESS,
  DELETE_MATIERE_ERR,

  UPDATE_MATIERE_BEGIN,
  UPDATE_MATIERE_SUCCESS,
  UPDATE_MATIERE_ERR,
} = actions;

const MatiereReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_MATIERE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_MATIERE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case UPDATE_MATIERE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_MATIERE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_MATIERE_SUCCESS:
      return {
        ...state,
        arrayData: data,
        loading: false,
      };
    case VIEW_MATIERE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_MATIERE_BY_LEVEL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_MATIERE_BY_LEVEL_SUCCESS:
      return {
        ...state,
        arrayDataLevel: data,
        loading: false,
      };
    case VIEW_MATIERE_BY_LEVEL_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_SINGLE_MATIERE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_SINGLE_MATIERE_SUCCESS:
      return {
        ...state,
        singleData: data,
        loading: false,
      };
    case VIEW_SINGLE_MATIERE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_MATIERE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_MATIERE_SUCCESS:
      const arrayData = state.arrayData.filter((item) => item.id != data);
      return {
        ...state,
        arrayData,
        loading: false,
      };
    case DELETE_MATIERE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_MATIERE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_MATIERE_SUCCESS:
      return {
        ...state,
        arrayData: [...state.arrayData, data],
        loading: false,
      };
    case ADD_MATIERE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default MatiereReducer;
