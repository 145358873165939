import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { List } from '../../../../components/pricing/pricing';
import Heading from '../../../../components/heading/heading';
import { Badge, PricingCard } from '../../../pages/style';
import { useDispatch, useSelector } from 'react-redux';
import { viewFactureData } from '../../../../redux/parent/facture/actionCreator';

function Tranches() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(viewFactureData());
  }, []);
  const { paiments } = useSelector((state) => {
    return {
      paiments: state.ParentFactureReducer.facture,
    };
  });
  useEffect(() => {
    if (paiments && paiments.modalites) {
      setData(paiments.modalites);
    }
  }, [paiments]);

  return (
    <Row gutter={25}>
      {data &&
        data.map((item, index) => {
          return (
            <>
              <Col xxl={8} lg={12} sm={12} xs={24} key={index}>
                <PricingCard style={{ marginBottom: 30 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Badge
                      className="pricing-badge"
                      type={item.paid ? (item.paid == item.prix ? 'success' : 'warning') : 'danger'}
                    >
                      Tranche : {item.mois}
                    </Badge>
                    <div style={{ display: 'flex', columnGap: '5px' }}></div>
                  </div>
                  <Heading className="price-amount" as="h6">
                    <sup className="currency"></sup>
                    {item.paid ? item.paid : '0'}/{item.prix} DT <sub className="pricing-validity">par tranche</sub>
                  </Heading>
                </PricingCard>
              </Col>
            </>
          );
        })}
    </Row>
  );
}

export default Tranches;
