const actions = {
  VIEW_DEVOIR_BEGIN: 'VIEW_DEVOIR_BEGIN',
  VIEW_DEVOIR_SUCCESS: 'VIEW_DEVOIR_SUCCESS',
  VIEW_DEVOIR_ERR: 'VIEW_DEVOIR_ERR',

  VIEW_SINGLE_DEVOIR_BEGIN: 'VIEW_SINGLE_DEVOIR_BEGIN',
  VIEW_SINGLE_DEVOIR_SUCCESS: 'VIEW_SINGLE_DEVOIR_SUCCESS',
  VIEW_SINGLE_DEVOIR_ERR: 'VIEW_SINGLE_DEVOIR_ERR',

  ADD_DEVOIR_BEGIN: 'ADD_DEVOIR_BEGIN',
  ADD_DEVOIR_SUCCESS: 'ADD_DEVOIR_SUCCESS',
  ADD_DEVOIR_ERR: 'ADD_DEVOIR_ERR',

  DELETE_DEVOIR_BEGIN: 'DELETE_DEVOIR_BEGIN',
  DELETE_DEVOIR_SUCCESS: 'DELETE_DEVOIR_SUCCESS',
  DELETE_DEVOIR_ERR: 'DELETE_DEVOIR_ERR',

  UPDATE_DEVOIR_BEGIN: 'UPDATE_DEVOIR_BEGIN',
  UPDATE_DEVOIR_SUCCESS: 'UPDATE_DEVOIR_SUCCESS',
  UPDATE_DEVOIR_ERR: 'UPDATE_DEVOIR_ERR',

  viewDevoirBegin: () => {
    return {
      type: actions.VIEW_DEVOIR_BEGIN,
    };
  },

  viewDevoirSuccess: (data) => {
    return {
      type: actions.VIEW_DEVOIR_SUCCESS,
      data,
    };
  },

  viewDevoirErr: (err) => {
    return {
      type: actions.VIEW_DEVOIR_ERR,
      err,
    };
  },

  viewSingleDevoirBegin: () => {
    return {
      type: actions.VIEW_SINGLE_DEVOIR_BEGIN,
    };
  },

  viewSingleDevoirSuccess: (data) => {
    return {
      type: actions.VIEW_SINGLE_DEVOIR_SUCCESS,
      data,
    };
  },

  viewSingleDevoirErr: (err) => {
    return {
      type: actions.VIEW_SINGLE_DEVOIR_ERR,
      err,
    };
  },

  updateDevoirBegin: () => {
    return {
      type: actions.UPDATE_DEVOIR_BEGIN,
    };
  },

  updateDevoirSuccess: (data) => {
    return {
      type: actions.UPDATE_DEVOIR_SUCCESS,
      data,
    };
  },

  updateDevoirErr: (err) => {
    return {
      type: actions.UPDATE_DEVOIR_ERR,
      err,
    };
  },
  deleteDevoirBegin: () => {
    return {
      type: actions.DELETE_DEVOIR_BEGIN,
    };
  },

  deleteDevoirSuccess: (data) => {
    return {
      type: actions.DELETE_DEVOIR_SUCCESS,
      data,
    };
  },

  deleteDevoirErr: (err) => {
    return {
      type: actions.DELETE_DEVOIR_ERR,
      err,
    };
  },
  addDevoirBegin: () => {
    return {
      type: actions.ADD_DEVOIR_BEGIN,
    };
  },

  addDevoirSuccess: (data) => {
    return {
      type: actions.ADD_DEVOIR_SUCCESS,
      data,
    };
  },

  addDevoirErr: (err) => {
    return {
      type: actions.ADD_DEVOIR_ERR,
      err,
    };
  },
};

export default actions;
