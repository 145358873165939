import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  addInscriptionBegin,
  addInscriptionSuccess,
  addInscriptionErr,
  updateInscriptionBegin,
  updateInscriptionSuccess,
  updateInscriptionErr,
  viewInscriptionBegin,
  viewInscriptionSuccess,
  viewInscriptionErr,

  viewInscriptionClubsBegin,
  viewInscriptionClubsSuccess,
  viewInscriptionClubsErr,
  viewInscriptionEventsBegin,
  viewInscriptionEventsSuccess,
  viewInscriptionEventsErr,

  viewFactureBegin,
  viewFactureSuccess,
  viewFactureErr,
  viewSingleInscriptionBegin,
  viewSingleInscriptionSuccess,
  viewSingleInscriptionErr,
  deleteInscriptionBegin,
  deleteInscriptionSuccess,
  deleteInscriptionErr,
} = actions;

const viewInscriptionData = () => {
  return async (dispatch) => {
    dispatch(viewInscriptionBegin());
    try {
      const response = await DataService.get('/inscription/view');

      if (response.status === 200) {
        dispatch(viewInscriptionSuccess(response.data.payload));
      } else {
        dispatch(viewInscriptionErr('err'));
      }
    } catch (err) {
      dispatch(viewInscriptionErr('err'));
    }
  };
};
const viewFactureData = (id) => {
  return async (dispatch) => {
    dispatch(viewFactureBegin());
    try {
      const response = await DataService.get(`/paiment/view/${id}`);

      if (response.status === 200) {
        dispatch(viewFactureSuccess(response.data));
      } else {
        dispatch(viewFactureErr('err'));
      }
    } catch (err) {
      dispatch(viewFactureErr('err'));
    }
  };
};

const viewInscriptionClubsData = (id) => {
  return async (dispatch) => {
    dispatch(viewInscriptionClubsBegin());
    try {
      const response = await DataService.get(`/inscription/getInscriptionClubs/${id}`);
      if (response.status === 200) {
        dispatch(viewInscriptionClubsSuccess(response.data.clubs));
      } else {
        dispatch(viewInscriptionClubsErr('err'));
      }
    } catch (err) {
      dispatch(viewInscriptionClubsErr('err'));
    }
  };
};

const viewInscriptionEventsData = (id) => {
  return async (dispatch) => {
    dispatch(viewInscriptionEventsBegin());
    try {
      const response = await DataService.get(`/inscription/getInscriptionEvents/${id}`);
      if (response.status === 200) {
        dispatch(viewInscriptionEventsSuccess(response.data.events));
      } else {
        dispatch(viewInscriptionEventsErr('err'));
      }
    } catch (err) {
      dispatch(viewInscriptionEventsErr('err'));
    }
  };
};

const viewSingleInscriptionData = (id) => {
  return async (dispatch) => {
    dispatch(viewSingleInscriptionBegin());
    try {
      const response = await DataService.get(`/inscription/singleInfo/${id}`);
      if (response.status === 200) {
        dispatch(viewSingleInscriptionSuccess(response.data.payload));
      } else {
        dispatch(viewSingleInscriptionErr('err'));
      }
    } catch (err) {
      dispatch(viewSingleInscriptionErr('err'));
    }
  };
};

const addInscriptionData = (data, callback) => {
  return async (dispatch) => {
    dispatch(addInscriptionBegin());
    try {
      const response = await DataService.post('/inscription/add', data);
      if (response.status === 200) {
        dispatch(addInscriptionSuccess(response.data.payload));
        message.success(response.data.message);
        callback();
      } else {
        dispatch(addInscriptionErr('err'));
      }
    } catch (err) {
      dispatch(addInscriptionErr('err'));
    }
  };
};
const deleteInscriptionData = (id) => {
  return async (dispatch) => {
    dispatch(deleteInscriptionBegin());
    try {
      const response = await DataService.delete(`/inscription/delete/${id}`);
      if (response.status === 200) {
        dispatch(deleteInscriptionSuccess(id));
        message.success(response.data.message);
      } else {
        dispatch(deleteInscriptionErr('err'));
      }
    } catch (err) {
      dispatch(deleteInscriptionErr('err'));
    }
  };
};
const updateInscriptionData = (id, data, callback) => {
  return async (dispatch) => {
    dispatch(updateInscriptionBegin());
    try {
      const response = await DataService.put(`/inscription/updated/${id}`, data);
      if (response.status === 200) {
        dispatch(updateInscriptionSuccess(data));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(updateInscriptionErr('err'));
      }
    } catch (err) {
      dispatch(updateInscriptionErr('err'));
    }
  };
};

export {
  viewInscriptionData,
  viewSingleInscriptionData,
  updateInscriptionData,
  deleteInscriptionData,
  addInscriptionData,
  viewFactureData,
  viewInscriptionClubsData,
  viewInscriptionEventsData,
};
