import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Checkbox, Divider, Form, message, Input } from 'antd';
import { Badge, PricingCard } from '../../pages/style';
import { useDispatch, useSelector } from 'react-redux';
import Heading from '../../../components/heading/heading';
import { viewFactureData } from '../../../redux/inscription/actionCreator';
import { useParams } from 'react-router-dom';
import { Button } from '../../../components/buttons/buttons';
import { addPaimentData } from '../../../redux/paiment/actionCreator';
import { printInvoice } from '../../../utility/printInvoice';

import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

function Tranches() {
  let { id } = useParams();

  const [form] = Form.useForm();
  const [paimentForm] = Form.useForm();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [factureData, setFactureData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState(1);

  const toggleState = () => {
    setState((prev) => (prev === 1 ? -1 : 1));
  };
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const [isPaimentOpen, setIsPaimentOpen] = useState(false);
  const [selectedTranche, setSelectedTranche] = useState(null);

  const handlePaimentOpen = (data) => {
    if (!data.paid || data.paid < data.prix) {
      setSelectedTranche(data);
      setIsPaimentOpen(true);
    }
  };
  const handlePaimentClose = () => {
    paimentForm.resetFields();
    setSelectedTranche(null);
    setIsPaimentOpen(false);
  };

  const [clubOptions, setClubOptions] = useState([]);
  const [eventOptions, setEventOptions] = useState([]);
  const [installmentOptions, setInstallmentOptions] = useState([]);
  const [otherInstallmentOptions, setOtherInstallmentOptions] = useState([]);

  const CheckboxGroup = Checkbox.Group;

  const { facture } = useSelector((state) => {
    return {
      facture: state.InscriptionReducer.facture,
    };
  });

  useEffect(() => {
    dispatch(viewFactureData(id));
  }, []);

  useEffect(() => {
    if (facture != null) {
      setData(facture.modalites);
    }
  }, [facture]);
  /*useEffect(() => {
    if (facture && facture.tranches) {
      const transformed = transformData(facture);

      setFactureData(transformData(facture));

      setClubOptions(
        transformed.clubRegistrations.map((club) => ({
          label: club.title,
          value: club.id,
        })),
      );

      setEventOptions(
        transformed.eventRegistrations.map((event) => ({
          label: event.title,
          value: event.id,
        })),
      );

      setInstallmentOptions(
        transformed.installments.map((installment) => ({
          label: installment.name,
          value: installment.name,
        })),
      );

      setOtherInstallmentOptions(
        transformed.otherInstallments.map((installment) => ({
          label: installment.name,
          value: installment.name,
        })),
      );
    }
  }, [facture]);*/

  const addPaiment = (values) => {
    values.etudiant_id = id;
    values.modalite_id = selectedTranche.id;
    values.signe = state == -1 ? 'moins' : 'plus';

    dispatch(addPaimentData(values, handlePaimentClose));
  };

  function filterData(filters) {
    const clubRegistrations =
      filters.clubs && factureData.clubRegistrations.filter((club) => filters.clubs.includes(club.id));
    const eventRegistrations =
      filters.events && factureData.eventRegistrations.filter((event) => filters.events.includes(event.id));
    const installments =
      filters.tranches && factureData.installments.filter((tranche) => filters.tranches.includes(tranche.name));

    const otherInstallments =
      filters.tranches &&
      factureData.otherInstallments.filter((tranche) => filters.autreTranches.includes(tranche.name));

    return {
      ...factureData,
      clubRegistrations,
      eventRegistrations,
      installments,
      otherInstallments,
    };
  }
  const handleSubmit = (values) => {
    if (values.clubs || values.events || values.tranches) {
      printInvoice(filterData(values));
    } else {
      message.error('Tous les champs obligatoires sont requis');
      return;
    }
  };
  return (
    <Row gutter={25}>
      <Col xxl={24} lg={24} sm={24} xs={24}>
        <Button
          key="1"
          type="danger"
          size="default"
          style={{ borderRadius: '17px', marginBottom: '20px' }}
          onClick={handleOpen}
        >
          Imprimer facture
        </Button>
      </Col>
      <Modal open={isOpen} onCancel={handleClose} footer={null} title={'Parametre facture'}>
        <Row gutter={25}>
          <Form form={form} onFinish={handleSubmit}>
            <Divider orientation="left">Clubs</Divider>
            <Form.Item name="clubs">
              <CheckboxGroup options={clubOptions} style={{ marginLeft: '30px', color: 'black' }} />
            </Form.Item>
            <Divider orientation="left">Events</Divider>
            <Form.Item name="events">
              <CheckboxGroup options={eventOptions} style={{ marginLeft: '30px', color: 'black' }} />
            </Form.Item>
            <Divider orientation="left">Tranches</Divider>
            <Form.Item name="tranches">
              <CheckboxGroup
                options={installmentOptions}
                style={{ display: 'flex', flexDirection: 'column', marginLeft: '30px' }}
              />
            </Form.Item>
            <Divider orientation="left">Autre Tranches</Divider>
            <Form.Item name="autreTranches">
              <CheckboxGroup
                options={otherInstallmentOptions}
                style={{ display: 'flex', flexDirection: 'column', marginLeft: '30px' }}
              />
            </Form.Item>
            <div className="add-event-footer text-right" style={{ width: '480px' }}>
              <Button htmlType="submit" className="btn-save" type="primary">
                Imprimer
              </Button>
            </div>
          </Form>
        </Row>
      </Modal>
      <Modal open={isPaimentOpen} onCancel={handlePaimentClose} footer={null} title={`Mois : ${selectedTranche?.mois}`}>
        <Row gutter={25}>
          <Form form={paimentForm} onFinish={addPaiment}>
            <Form.Item name="payer">
              <Input placeholder="Saisir le montant a payer" style={{ marginLeft: '10px', color: 'black' }} />
            </Form.Item>
            <div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Button
                type={state === -1 ? 'primary' : 'default'}
                style={{ borderRadius: '10px', height: '35px' }}
                icon={<MinusOutlined />}
                onClick={toggleState}
              >
                décrémenter
              </Button>
              <Button
                type={state === 1 ? 'primary' : 'default'}
                style={{ borderRadius: '10px', height: '35px' }}
                icon={<PlusOutlined />}
                onClick={toggleState}
              >
                incrémenter
              </Button>
            </div>
            <br />
            <div className="add-event-footer text-right" style={{ width: '480px' }}>
              <Button htmlType="submit" className="btn-save" type="primary">
                payer
              </Button>
            </div>
          </Form>
        </Row>
      </Modal>
      {data
        ?.sort((a, b) => a.id > b.id)
        .map((item) => {
          return (
            <>
              <Col xxl={8} lg={12} sm={12} xs={24} key={item.id}>
                <PricingCard style={{ marginBottom: 30 }} onClick={() => handlePaimentOpen(item)}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Badge
                      className="pricing-badge"
                      type={item.paid ? (item.paid == item.prix ? 'success' : 'warning') : 'danger'}
                    >
                      Tranche : {item.mois}
                    </Badge>
                    <div style={{ display: 'flex', columnGap: '5px' }}></div>
                  </div>
                  <Heading className="price-amount" as="h6">
                    <sup className="currency"></sup>
                    {item.paid ? item.paid : '0'}/{item.prix} DT <sub className="pricing-validity">par tranche</sub>
                  </Heading>
                </PricingCard>
              </Col>
            </>
          );
        })}
    </Row>
  );
}

export default Tranches;
