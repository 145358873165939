/* eslint-disable react/jsx-no-bind */
import { Badge } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { UserActionDropDown } from './auth-info-style';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';
import { viewInscriptionData } from '../../../redux/prof/inscription/actionCreator';

const MessageBox = React.memo(() => {
  const dispatch = useDispatch();
  const { rooms } = useSelector((state) => {
    return {
      rooms: state.ProfInscriptionReducer.arrayData,
    };
  });

  useEffect(() => {
    dispatch(viewInscriptionData());
  }, []);

  function renderThumb({ style }) {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} />;
  }

  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      right: '2px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div className="hello" style={thumbStyle} />;
  };

  function renderView({ style }) {
    const customStyle = {
      marginRight: '-17px',
    };
    return <div style={{ ...style, ...customStyle }} />;
  }

  renderThumb.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  renderView.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  const content = (
    <UserActionDropDown className="edulink-top-dropdown">
      <Heading className="edulink-top-dropdown__title" as="h5">
        <span className="title-text">Messages</span>
      </Heading>
      <Scrollbars
        autoHeight
        autoHide
        renderThumbVertical={renderThumb}
        renderView={renderView}
        renderTrackVertical={renderTrackVertical}
        renderTrackHorizontal={(props) => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
      >
        <div className="edulink-top-dropdown-menu">
          <ul className="edulink-top-dropdown__nav">
            {rooms?.map((item, index) => (
              <li key={index}>
                <Link to={`/chat/private/${item.id}`}>
                  <figure className="edulink-top-dropdown__content">
                    {/* <img src={require('../../../static/img/avatar/NoPath.png')} alt="" style={{ borderRadius: '50px' }} /> */}
                    <img src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />

                    <figcaption>
                      <div className="room-overview">
                        <div className="room-inscription">
                          {item.prenom} {item.nom}
                        </div>{' '}
                        <div className="color-success" style={{ justifySelf: 'end' }}></div>
                      </div>
                      <div>
                        <span className="edulink-top-dropdownText">{`${item.classe.niveau} ${
                          item.classe.niveau == 1 ? 'ère' : 'ème'
                        } année ${item.classe.titre} `}</span>
                      </div>
                    </figcaption>
                  </figure>
                </Link>
              </li>
            ))}
            <ul />
          </ul>
        </div>
      </Scrollbars>
      {rooms.length > 0 && (
        <Link className="btn-seeAll" to={`/chat/private/${rooms[0].id}`}>
          See all messages
        </Link>
      )}
    </UserActionDropDown>
  );

  return (
    <div className="edulink-nav-actions__item edulink-nav-actions__message">
      <Popover placement="bottomLeft" content={content} action="click">
        <Badge  offset={[-8, -5]}>
          <Link to="#" className="edulink-nav-action-link">
            <ReactSVG src={require('../../../static/img/icon/envelope.svg').default} />
          </Link>
        </Badge>
      </Popover>
    </div>
  );
});

export default MessageBox;
