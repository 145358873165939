import { message } from 'antd';
import actions from './actions';

const initialState = {
  data: [],
  bulletin: null,
  loading: false,
  error: null,
};

const {
  VIEW_NOTE_BEGIN,
  VIEW_NOTE_SUCCESS,
  VIEW_NOTE_ERR,

  VIEW_BULLETIN_BEGIN,
  VIEW_BULLETIN_SUCCESS,
  VIEW_BULLETIN_ERR,

  ADD_NOTE_BEGIN,
  ADD_NOTE_SUCCESS,
  ADD_NOTE_ERR,

  UPDATE_NOTE_BEGIN,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_ERR,
} = actions;

const NoteReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_NOTE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_NOTE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_NOTE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_NOTE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_NOTE_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case VIEW_NOTE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_BULLETIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_BULLETIN_SUCCESS:
      return {
        ...state,
        bulletin: data,
        loading: false,
      };
    case VIEW_BULLETIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_NOTE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_NOTE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_NOTE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export default NoteReducer;
