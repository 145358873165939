const actions = {
  VIEW_MATIERE_BEGIN: 'VIEW_MATIERE_BEGIN',
  VIEW_MATIERE_SUCCESS: 'VIEW_MATIERE_SUCCESS',
  VIEW_MATIERE_ERR: 'VIEW_MATIERE_ERR',

  VIEW_MATIERE_BY_LEVEL_BEGIN: 'VIEW_MATIERE_BY_LEVEL_BEGIN',
  VIEW_MATIERE_BY_LEVEL_SUCCESS: 'VIEW_MATIERE_BY_LEVEL_SUCCESS',
  VIEW_MATIERE_BY_LEVEL_ERR: 'VIEW_MATIERE_BY_LEVEL_ERR',

  VIEW_SINGLE_MATIERE_BEGIN: 'VIEW_SINGLE_MATIERE_BEGIN',
  VIEW_SINGLE_MATIERE_SUCCESS: 'VIEW_SINGLE_MATIERE_SUCCESS',
  VIEW_SINGLE_MATIERE_ERR: 'VIEW_SINGLE_MATIERE_ERR',

  ADD_MATIERE_BEGIN: 'ADD_MATIERE_BEGIN',
  ADD_MATIERE_SUCCESS: 'ADD_MATIERE_SUCCESS',
  ADD_MATIERE_ERR: 'ADD_MATIERE_ERR',

  DELETE_MATIERE_BEGIN: 'DELETE_MATIERE_BEGIN',
  DELETE_MATIERE_SUCCESS: 'DELETE_MATIERE_SUCCESS',
  DELETE_MATIERE_ERR: 'DELETE_MATIERE_ERR',

  UPDATE_MATIERE_BEGIN: 'UPDATE_MATIERE_BEGIN',
  UPDATE_MATIERE_SUCCESS: 'UPDATE_MATIERE_SUCCESS',
  UPDATE_MATIERE_ERR: 'UPDATE_MATIERE_ERR',

  viewMatiereBegin: () => {
    return {
      type: actions.VIEW_MATIERE_BEGIN,
    };
  },

  viewMatiereSuccess: (data) => {
    return {
      type: actions.VIEW_MATIERE_SUCCESS,
      data,
    };
  },

  viewMatiereErr: (err) => {
    return {
      type: actions.VIEW_MATIERE_ERR,
      err,
    };
  },

  viewMatiereByLevelBegin: () => {
    return {
      type: actions.VIEW_MATIERE_BY_LEVEL_BEGIN,
    };
  },

  viewMatiereByLevelSuccess: (data) => {
    return {
      type: actions.VIEW_MATIERE_BY_LEVEL_SUCCESS,
      data,
    };
  },

  viewMatiereByLevelErr: (err) => {
    return {
      type: actions.VIEW_MATIERE_BY_LEVEL_ERR,
      err,
    };
  },

  viewSingleMatiereBegin: () => {
    return {
      type: actions.VIEW_SINGLE_MATIERE_BEGIN,
    };
  },

  viewSingleMatiereSuccess: (data) => {
    return {
      type: actions.VIEW_SINGLE_MATIERE_SUCCESS,
      data,
    };
  },

  viewSingleMatiereErr: (err) => {
    return {
      type: actions.VIEW_SINGLE_MATIERE_ERR,
      err,
    };
  },

  updateMatiereBegin: () => {
    return {
      type: actions.UPDATE_MATIERE_BEGIN,
    };
  },

  updateMatiereSuccess: (data) => {
    return {
      type: actions.UPDATE_MATIERE_SUCCESS,
      data,
    };
  },

  updateMatiereErr: (err) => {
    return {
      type: actions.UPDATE_MATIERE_ERR,
      err,
    };
  },
  deleteMatiereBegin: () => {
    return {
      type: actions.DELETE_MATIERE_BEGIN,
    };
  },

  deleteMatiereSuccess: (data) => {
    return {
      type: actions.DELETE_MATIERE_SUCCESS,
      data,
    };
  },

  deleteMatiereErr: (err) => {
    return {
      type: actions.DELETE_MATIERE_ERR,
      err,
    };
  },
  addMatiereBegin: () => {
    return {
      type: actions.ADD_MATIERE_BEGIN,
    };
  },

  addMatiereSuccess: (data) => {
    return {
      type: actions.ADD_MATIERE_SUCCESS,
      data,
    };
  },

  addMatiereErr: (err) => {
    return {
      type: actions.ADD_MATIERE_ERR,
      err,
    };
  },
};

export default actions;
