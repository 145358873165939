const actions = {
  VIEW_FRAIS_BEGIN: 'VIEW_FRAIS_BEGIN',
  VIEW_FRAIS_SUCCESS: 'VIEW_FRAIS_SUCCESS',
  VIEW_FRAIS_ERR: 'VIEW_FRAIS_ERR',

  ADD_FRAIS_BEGIN: 'ADD_FRAIS_BEGIN',
  ADD_FRAIS_SUCCESS: 'ADD_FRAIS_SUCCESS',
  ADD_FRAIS_ERR: 'ADD_FRAIS_ERR',

  DELETE_FRAIS_BEGIN: 'DELETE_FRAIS_BEGIN',
  DELETE_FRAIS_SUCCESS: 'DELETE_FRAIS_SUCCESS',
  DELETE_FRAIS_ERR: 'DELETE_FRAIS_ERR',

  UPDATE_FRAIS_BEGIN: 'UPDATE_FRAIS_BEGIN',
  UPDATE_FRAIS_SUCCESS: 'UPDATE_FRAIS_SUCCESS',
  UPDATE_FRAIS_ERR: 'UPDATE_FRAIS_ERR',

  viewFraisBegin: () => {
    return {
      type: actions.VIEW_FRAIS_BEGIN,
    };
  },

  viewFraisSuccess: (data) => {
    return {
      type: actions.VIEW_FRAIS_SUCCESS,
      data,
    };
  },

  viewFraisErr: (err) => {
    return {
      type: actions.VIEW_FRAIS_ERR,
      err,
    };
  },

  updateFraisBegin: () => {
    return {
      type: actions.UPDATE_FRAIS_BEGIN,
    };
  },

  updateFraisSuccess: (data) => {
    return {
      type: actions.UPDATE_FRAIS_SUCCESS,
      data,
    };
  },

  updateFraisErr: (err) => {
    return {
      type: actions.UPDATE_FRAIS_ERR,
      err,
    };
  },
  deleteFraisBegin: () => {
    return {
      type: actions.DELETE_FRAIS_BEGIN,
    };
  },

  deleteFraisSuccess: (data) => {
    return {
      type: actions.DELETE_FRAIS_SUCCESS,
      data,
    };
  },

  deleteFraisErr: (err) => {
    return {
      type: actions.DELETE_FRAIS_ERR,
      err,
    };
  },
  addFraisBegin: () => {
    return {
      type: actions.ADD_FRAIS_BEGIN,
    };
  },

  addFraisSuccess: (data) => {
    return {
      type: actions.ADD_FRAIS_SUCCESS,
      data,
    };
  },

  addFraisErr: (err) => {
    return {
      type: actions.ADD_FRAIS_ERR,
      err,
    };
  },
};

export default actions;
