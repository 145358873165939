/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Upload, DatePicker, Select, TimePicker, message } from 'antd';
import { FormValidationWrap, VerticalFormStyleWrap } from './overview/Style';
import { Main } from '../../styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import locale from 'antd/es/date-picker/locale/fr_FR'; // Import the French locale
import { useDispatch, useSelector } from 'react-redux';
import { viewClasseData } from '../../../redux/prof/classe/actionCreator';
import { updateExerciceData, viewSingleExerciceData } from '../../../redux/prof/exercice/actionCreator';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { getItem } from '../../../utility/localStorageControl';
import moment from 'moment';
import { viewMatiereData } from '../../../redux/prof/matiere/actionCreator';
function UpdateExerciceForm() {
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewClasseData());
  }, []);
  const [form] = Form.useForm();
  const [niveau, setNiveau] = useState();
  const [matiere, setMatiere] = useState();
  const [classeId, setClasseId] = useState();

  const [classesSelect, setClassesSelect] = useState();
  const [fileName, setFileName] = useState();
  const [matieresData, setMatieresData] = useState([]);

  const submitSuccess = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(viewClasseData());
    dispatch(viewSingleExerciceData(id));
    dispatch(viewMatiereData());
  }, []);

  const { classes, exercice, matieres } = useSelector((state) => {
    return {
      classes: state.ProfClasseReducer.arrayData,
      exercice: state.ProfExerciceReducer.singleData,
      matieres: state.MatiereProfReducer.data,
    };
  });

  useEffect(() => {
    if (matieres.length > 0) {
      const data = matieres
        .filter((item, index, self) => index === self.findIndex((t) => t.nom === item.nom) && item.niveau == niveau)
        .map((item) => ({
          label: item.nom,
          value: item.nom,
        }));
      setMatieresData(data);
    }
  }, [niveau, matieres]);

  useEffect(() => {
    if (exercice) {
      const [classe] = classes.filter((item) => item.id == exercice.classe_id);
      setNiveau(`${classe.niveau}`);
      setClasseId(exercice.classe_id);
      console.log(exercice);
      setMatiere(exercice.matiere);
      form.setFieldsValue({
        date: moment(exercice.date, 'YYYY-MM-DD'),
        dateL: moment(exercice.dateL, 'YYYY-MM-DD'),
        heur: moment(exercice.heur, 'HH:mm'),
        heurL: moment(exercice.heurL, 'HH:mm'),
        matiere: exercice.matiere,
        description: exercice.description,
        classe_id: exercice.classe_id,
        image: [
          {
            name: exercice.image,
            status: 'done',
            url: `${process.env.REACT_APP_IMAGE_BASE_URL}/${exercice.image}`,
          },
        ],
      });
    }
  }, [exercice]);

  useEffect(() => {
    if (exercice) {
      const classe = classes.filter((item) => item.id == exercice.classe_id);
      if (niveau && niveau != classe[0].niveau) {
        setClasseId(null);
      }
    }
    const localExerice = classes.filter((item) => item.niveau == niveau);
    const selectClasseData = localExerice.map((item) => {
      return { value: item.id, label: item.titre };
    });
    setClassesSelect(selectClasseData);
  }, [niveau]);

  useEffect(() => {
    const data = classes.filter((item) => item.niveau == niveau);
    const selectClasseData = data.map((item) => {
      return { value: item.id, label: item.titre };
    });
    setClassesSelect(selectClasseData);
  }, [niveau]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleSubmit = (values) => {
    values.date = values.date.format('YYYY-MM-DD');
    values.dateL = values.dateL.format('YYYY-MM-DD');
    values.heurL = values.heurL.format('HH:mm');
    values.heur = values.heur.format('HH:mm');
    if (fileName) {
      const isPdf = /(\.pdf)$/i.test(fileName);
      isPdf ? (values.pdf = fileName) : (values.image = fileName);
    }

    dispatch(updateExerciceData(exercice.id, values, submitSuccess));
  };

  const props = {
    name: 'file',
    multiple: false,
    listType: 'picture',
    customRequest({ file, onSuccess, onError }) {
      const formData = new FormData();
      formData.append('file', file);
      axios
        .post('https://edulinkbackend.edulink.tn/api/image/Professeur/upload', formData, {
          headers: {
            Authorization: `Bearer ${getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          onSuccess(response.data, file);
          setFileName(response.data.payload);
        });
    },
    beforeUpload(file) {
      return true;
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleAnnuler = () => {
    navigate(-1);
  };
  return (
    <>
      <Main>
        <Row gutter={25}>
          <Col xs={24}>
            <FormValidationWrap>
              <VerticalFormStyleWrap>
                <Cards headless>
                  <Form name="sDash_validation-form" form={form} layout="vertical" onFinish={handleSubmit}>
                    <div className="form-title">Ajouter Un Exercice </div>
                    <Row gutter={30}>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="niveau"
                          label="Niveau"
                          rules={[{ required: true, message: 'Niveau est obligatoire!' }]}
                        >
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Niveau"
                            value={niveau}
                            onChange={(e) => setNiveau(e)}
                            options={[
                              { value: '1', label: '1ère année' },
                              { value: '2', label: '2ème année' },
                              { value: '3', label: '3ème année' },
                              { value: '4', label: '4ème année' },
                              { value: '5', label: '5ème année' },
                              { value: '6', label: '6ème année' },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="classe_id"
                          label="Classe"
                          rules={[{ required: true, message: 'Classe est obligatoire!' }]}
                        >
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Classe"
                            options={classesSelect}
                            value={classeId}
                            onChange={(e) => setClasseId(e)}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={24} xs={24}>
                        <Form.Item
                          name="matiere"
                          label="Matière"
                          rules={[{ required: true, message: 'Matière est obligatoire !' }]}
                        >
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Niveau"
                            options={matieresData}
                            value={matiere}
                            onChange={(value) => {
                              setMatiere(value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="date"
                          label="Date Debut"
                          rules={[{ required: true, message: 'Date debut est obligatoire!' }]}
                        >
                          <DatePicker format="yyyy/MM/DD" locale={locale} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="dateL"
                          label="Date Limite"
                          rules={[{ required: true, message: 'Date Limite est obligatoire!' }]}
                        >
                          <DatePicker format="yyyy/MM/DD" locale={locale} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Heur Limite Debut"
                          name="heur"
                          rules={[{ required: true, message: 'Heur limite debut est obligatoire!' }]}
                        >
                          <TimePicker format="HH:mm" locale={locale} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Heur Limite Fin"
                          name="heurL"
                          rules={[{ required: true, message: 'Heur limite fin est obligatoire!' }]}
                        >
                          <TimePicker format="HH:mm" locale={locale} />
                        </Form.Item>
                      </Col>

                      <Col md={24} xs={24}>
                        <Form.Item
                          name="description"
                          label="Description"
                          rules={[{ required: true, message: 'Description est obligatoire !' }]}
                        >
                          <Input.TextArea placeholder="Description ..." />
                        </Form.Item>
                      </Col>
                      <Col md={24} xs={24}>
                        <Form.Item valuePropName="fileList" getValueFromEvent={normFile} label="Ajouter un fichier">
                          <Upload.Dragger maxCount={1} name="files" {...props}>
                            <p className="ant-upload-hint">sélectionner ou faire glisser des fichiers ici</p>
                            <p className="ant-upload-text">JPG, PNG or PDF, file size pas plus que 10MB</p>
                          </Upload.Dragger>
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="edulink-form-action mt-20">
                      <Button htmlType="reset" type="light" size="large" onClick={handleAnnuler}>
                        Annuler
                      </Button>
                      <Button htmlType="submit" type="danger" size="large">
                        Ajouter
                      </Button>
                    </div>
                  </Form>
                </Cards>
              </VerticalFormStyleWrap>
            </FormValidationWrap>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default UpdateExerciceForm;
