import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  addFraisBegin,
  addFraisSuccess,
  addFraisErr,
  updateFraisBegin,
  updateFraisSuccess,
  updateFraisErr,
  viewFraisBegin,
  viewFraisSuccess,
  viewFraisErr,
  deleteFraisBegin,
  deleteFraisSuccess,
  deleteFraisErr,
} = actions;

const viewFraisData = () => {
  return async (dispatch) => {
    dispatch(viewFraisBegin());
    try {
      const response = await DataService.get('/modalites');
      if (response.status === 200) {
        dispatch(viewFraisSuccess(response.data));
      } else {
        dispatch(viewFraisErr('err'));
      }
    } catch (err) {
      dispatch(viewFraisErr('err'));
    }
  };
};

const addFraisData = (data, callback) => {
  return async (dispatch) => {
    dispatch(addFraisBegin());
    try {
      const response = await DataService.post(`/modalites`, data);
      if (response.status === 201) {
        dispatch(addFraisSuccess(response.data));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(addFraisErr('err'));
      }
    } catch (err) {
      dispatch(addFraisErr('err'));
    }
  };
};
const deleteFraisData = (id) => {
  return async (dispatch) => {
    dispatch(deleteFraisBegin());
    try {
      const response = await DataService.delete(`/modalites/${id}`, id);
      if (response.status === 200) {
        dispatch(deleteFraisSuccess(id));
        message.success(response.data.message);
      } else {
        dispatch(deleteFraisErr('err'));
      }
    } catch (err) {
      dispatch(deleteFraisErr(err));
    }
  };
};
const updateFraisData = (data, callback) => {
  return async (dispatch) => {
    dispatch(updateFraisBegin());

    try {
      const response = await DataService.post(`/modalites/storePrix`, data);
      if (response.status === 201) {
        dispatch(updateFraisSuccess());
        callback();
        message.success(response.data.message);
      } else {
        dispatch(updateFraisErr('err'));
      }
    } catch (err) {
      dispatch(updateFraisErr('err'));
    }
  };
};
export { viewFraisData, updateFraisData, deleteFraisData, addFraisData };
