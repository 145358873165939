/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Upload, TimePicker, notification, Select, message } from 'antd';
import { FormValidationWrap, VerticalFormStyleWrap } from './overview/Style';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import locale from 'antd/es/date-picker/locale/fr_FR'; // Import the French locale
import { useDispatch } from 'react-redux';
import { updateClubData } from '../../redux/club/actionCreator';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { getItem } from '../../utility/localStorageControl';
function UpdateClubForm() {
  const location = useLocation();
  let { club } = location.state;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fileName, setFileName] = useState();
  const [methode, SetMethode] = useState();
  const [tranches, SetTranches] = useState([]);
  useEffect(() => {
    if (methode == 1) {
      if (club.methode === 'PAR TRANCHE') {
        let localTranches = [];
        for (let i = 0; i < club.tranche.length; i++) {
          form.setFieldsValue({
            [`tranche-${i}`]: club.tranche[i],
            [`montant-${i}`]: club.montant[i],
          });

          localTranches.push({
            key: i,
            tranche: club.tranche[i],
            montant: club.montant[i],
          });
        }
        SetTranches(localTranches);
      } else {
        const key = new Date().valueOf();

        SetTranches([
          {
            key,
            tranche: '',
            montant: '',
          },
        ]);
      }
    } else {
      SetTranches([]);
    }
  }, [methode]);
  const addTranche = () => {
    const key = new Date().valueOf();
    SetTranches((tranches) => [...tranches, { tranche: '', montant: '', key }]);
  };
  const deleteTranche = (key) => {
    const tranchesUpdated = tranches.filter((item) => item.key != key);
    SetTranches(tranchesUpdated);
  };
  useEffect(() => {
    if (club) {
      SetMethode(club.methode === 'PAR TRANCHE' ? '1' : '2');
      form.setFieldsValue({
        titre: club.titre,
        jour: club.jour,
        frais: club.frais,
        heur: moment(club.heur, 'HH:mm'),
        adresse: club.adresse,
        description: club.description,
        methode: club.methode === 'PAR TRANCHE' ? '1' : '2',
        image: [
          {
            name: club.image,
            status: 'done',
            url: `${process.env.REACT_APP_IMAGE_BASE_URL}/${club.image}`,
          },
        ],
      });
      setFileName(club.image);
    }
  }, [club]);
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const updateSuccess = () => {
    navigate(-1);
  };
  const handleSubmit = (values) => {
    values.heur = values.heur.format('HH:mm');
    const formData = new FormData();
    formData.append('id', club.id);
    let frais = 0;
    delete values['image'];

    formData.append('donne[methode]', 'UNE SEUL FOIS');
    formData.append('donne[montant]', values.frais);

    formData.append('image', fileName);

    for (let key in values) {
      formData.append(key, values[key]);
    }

    dispatch(updateClubData(club.id, formData, updateSuccess));
  };

  const [form] = Form.useForm();
  const handleAnnuler = () => {
    navigate(-1);
  };

  const props = {
    name: 'file',
    multiple: false,
    listType: 'picture',
    customRequest({ file, onSuccess, onError }) {
      const formData = new FormData();
      formData.append('file', file);
      axios
        .post('https://edulinkbackend.edulink.tn/api/image/upload', formData, {
          headers: {
            Authorization: `Bearer ${getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          onSuccess(response.data, file);
          setFileName(response.data.payload);
        });
    },
    beforeUpload(file) {
      return true;
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  return (
    <>
      <Main>
        <Row gutter={25}>
          <Col xs={24}>
            <FormValidationWrap>
              <VerticalFormStyleWrap>
                <Cards headless>
                  <Form name="clubform" form={form} layout="vertical" onFinish={handleSubmit}>
                    <div className="form-title">Mettre club à jour</div>
                    <Row gutter={30}>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="titre"
                          label="Titre du club"
                          rules={[{ required: true, message: 'Titre du club est obligatoire !' }]}
                        >
                          <Input placeholder="Titre du club" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="jour"
                          label="Jour"
                          rules={[{ required: true, message: 'Jour est obligatoire!' }]}
                        >
                          <Input placeholder="Date" />
                        </Form.Item>
                      </Col>

                      <Col md={12} xs={24}>
                        <Form.Item
                          name="heur"
                          label="Heure de début"
                          rules={[{ required: true, message: 'Heure de début est obligatoire!' }]}
                        >
                          <TimePicker format="HH:mm" locale={locale} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="adresse"
                          label="Adresse"
                          rules={[{ required: true, message: 'Adresse est obligatoire!' }]}
                        >
                          <Input placeholder="Adresse" />
                        </Form.Item>
                      </Col>

                      <Col md={12} xs={24}>
                        <Form.Item
                          name="frais"
                          label="Frais"
                          rules={[{ required: true, message: 'Frais est obligatoire!' }]}
                        >
                          <Input placeholder="Frais" />
                        </Form.Item>
                      </Col>

                      <Col md={24} xs={24}>
                        <Form.Item
                          name="description"
                          label="Description"
                          rules={[{ required: true, message: 'Description est obligatoire !' }]}
                        >
                          <Input.TextArea placeholder="Description ..." />
                        </Form.Item>
                      </Col>
                      <Col md={24} xs={24}>
                        <Form.Item
                          name={'image'}
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                          label="Photo du Club"
                        >
                          <Upload.Dragger maxCount={1} name="files" {...props}>
                            <p className="ant-upload-hint">sélectionner ou faire glisser des fichiers ici</p>
                            <p className="ant-upload-text">JPG, PNG or PDF, file size pas plus que 10MB</p>
                          </Upload.Dragger>
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="edulink-form-action mt-20">
                      <Button htmlType="reset" type="light" size="large" onClick={handleAnnuler}>
                        Annuler
                      </Button>
                      <Button htmlType="submit" type="danger" size="large">
                        Ajouter
                      </Button>
                    </div>
                  </Form>
                </Cards>
              </VerticalFormStyleWrap>
            </FormValidationWrap>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default UpdateClubForm;
