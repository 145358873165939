import React, { useState, lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Skeleton, Select } from 'antd';
import { UsercardWrapper } from '../pages/style';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { viewClasseData } from '../../redux/classe/actionCreator';
import { viewInscriptionData } from '../../redux/inscription/actionCreator';
import { viewNoteData } from '../../redux/note/actionCreator';
import { viewMatiereByLevelData, viewMatiereData } from '../../redux/matiere/actionCreator';
import { viewDevoirData } from '../../redux/devoir/actionCreator';

const User = lazy(() => import('../pages/overview/UserCard'));

function Notes() {
  const dispatch = useDispatch();
  const [niveau, setNiveau] = useState();
  const [classesSelect, setClassesSelect] = useState();
  const [selectedClasse, setSelectedClasse] = useState();
  const [eleves, setEleves] = useState([]);
  const [selectedMatiere, setSelectedMatiere] = useState();

  const { classes, inscriptions, note, matieres, devoirs } = useSelector((state) => {
    return {
      classes: state.ClasseReducer.arrayData,
      inscriptions: state.InscriptionReducer.arrayData,
      note: state.NoteReducer.data,
      matieres: state.MatiereReducer.arrayDataLevel,
      devoirs: state.DevoirReducer.arrayData,
    };
  });
  const [matieresData, setMatieresData] = useState([]);
  useEffect(() => {
    dispatch(viewClasseData());
    dispatch(viewInscriptionData());
    dispatch(viewMatiereByLevelData());
  }, []);

  const filterMatieres = () => {
    matieres.find((item) => item.niveau == niveau)?.matiere || [];
    setMatieresData(
      (matieres.find((item) => item.niveau == niveau)?.matiere || []).map((item) => ({
        label: item.nom,
        value: item.id,
      })),
    );
  };

  useEffect(() => {
    if (classes && classes.length > 0 && matieres) {
      setNiveau('1');
    }
  }, [classes, matieres]);

  useEffect(() => {
    if (niveau && matieres) {
      filterMatieres();
      if (matieres.length == 0) {
        setSelectedMatiere(null);
      }
    }
  }, [niveau, matieres]);

  useEffect(() => {
    if (selectedMatiere) {
      dispatch(viewDevoirData(niveau, selectedMatiere));
    }
  }, [niveau, selectedMatiere]);

  useEffect(() => {
    if (niveau) {
      filterMatieres(niveau);
      const data = classes.filter((item) => item.niveau == niveau);
      const selectClasseData = data.map((item) => {
        return { value: item.id, label: item.titre };
      });
      setClassesSelect([...selectClasseData]);
      setSelectedClasse(selectClasseData[0] && selectClasseData[0].value);
    }
  }, [niveau]);

  useEffect(() => {
    if (selectedClasse && selectedMatiere) {
      dispatch(viewNoteData(selectedClasse, selectedMatiere));
    }
  }, [selectedClasse, selectedMatiere]);

  useEffect(() => {
    if (matieresData && matieresData.length > 0) {
      setSelectedMatiere(matieresData[0].value);
    } else {
      setSelectedMatiere(null);
    }
  }, [matieresData]);

  const GridView = React.memo(() => {
    return (
      note &&
      note.map((note, index) => {
        return (
          <Col key={index} xxl={6} xl={8} sm={12} xs={24}>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton avatar active />
                </Cards>
              }
            >
              <User etudiant={note} matiere={selectedMatiere} selectedClass={selectedClasse} devoirs={devoirs} />
            </Suspense>
          </Col>
        );
      })
    );
  });

  return (
    <>
      <Main>
        <div className="bulletin-classe-list-header">
          <Select
            style={{ width: '200px' }}
            placeholder="Choisir un niveau"
            value={niveau}
            onChange={(e) => setNiveau(e)}
            options={[
              { value: '1', label: '1ère année' },
              { value: '2', label: '2ème année' },
              { value: '3', label: '3ème année' },
              { value: '4', label: '4ème année' },
              { value: '5', label: '5ème année' },
              { value: '6', label: '6ème année' },
            ]}
          />
          <Select
            style={{ width: '200px' }}
            placeholder="Choisir un classe"
            options={classesSelect}
            value={selectedClasse}
            onChange={(e) => setSelectedClasse(e)}
          />
          <Select
            style={{ width: '200px' }}
            placeholder="Choisir une matière"
            value={selectedMatiere}
            onChange={(e) => setSelectedMatiere(e)}
            options={matieresData}
          />
        </div>
        <UsercardWrapper>
          <Row gutter={25}>
            <GridView />
          </Row>
        </UsercardWrapper>
      </Main>
    </>
  );
}

export default Notes;
