import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  addDevoirBegin,
  addDevoirSuccess,
  addDevoirErr,
  updateDevoirBegin,
  updateDevoirSuccess,
  updateDevoirErr,
  viewDevoirBegin,
  viewDevoirSuccess,
  viewDevoirErr,
  viewSingleDevoirBegin,
  viewSingleDevoirSuccess,
  viewSingleDevoirErr,
  deleteDevoirBegin,
  deleteDevoirSuccess,
  deleteDevoirErr,
} = actions;

const viewDevoirData = (niveau, idMatiere) => {
  return async (dispatch) => {
    dispatch(viewDevoirBegin());
    try {
      const response = await DataService.get(`devoires/getDevoires/${niveau}/${idMatiere}`);
      if (response.status === 200) {
        dispatch(viewDevoirSuccess(response.data.payload));
      } else {
        dispatch(viewDevoirErr('err'));
      }
    } catch (err) {
      dispatch(viewDevoirErr('err'));
    }
  };
};

const viewSingleDevoirData = (id) => {
  return async (dispatch) => {
    dispatch(viewSingleDevoirBegin());
    try {
      const response = await DataService.get(`/devoires/${id}`);

      if (response.status === 200) {
        dispatch(viewSingleDevoirSuccess(response.data.payload));
      } else {
        dispatch(viewSingleDevoirErr('err'));
      }
    } catch (err) {
      dispatch(viewSingleDevoirErr('err'));
    }
  };
};

const addDevoirData = (data, callback) => {
  return async (dispatch) => {
    dispatch(addDevoirBegin());
    try {
      const response = await DataService.post('/devoires', data);

      console.log(response.data);

      dispatch(addDevoirSuccess(response.data));
      callback();

      message.success(response.data.message);
    } catch (err) {
      dispatch(addDevoirErr('err'));
    }
  };
};
const deleteDevoirData = (id) => {
  return async (dispatch) => {
    dispatch(deleteDevoirBegin());
    try {
      const response = await DataService.delete(`/devoires/${id}`);
      if (response.status === 200) {
        dispatch(deleteDevoirSuccess(id));
        message.success(response.data.message);
      } else {
        dispatch(deleteDevoirErr('err'));
      }
    } catch (err) {
      dispatch(deleteDevoirErr('err'));
    }
  };
};
const updateDevoirData = (id, data, callback) => {
  return async (dispatch) => {
    dispatch(updateDevoirBegin());
    try {
      const response = await DataService.put(`/devoires/${id}`, data);

      if (response.status === 200) {
        dispatch(updateDevoirSuccess(response.data.payload));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(updateDevoirErr('err'));
      }
    } catch (err) {
      dispatch(updateDevoirErr('err'));
    }
  };
};

export { viewDevoirData, viewSingleDevoirData, updateDevoirData, deleteDevoirData, addDevoirData };
