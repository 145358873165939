/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Upload,
  TimePicker,
  DatePicker,
  Select,
  notification,
  Switch,
  message,
} from 'antd';
import { FormValidationWrap, VerticalFormStyleWrap } from './Style';
import { Main } from '../styled';
import { Cards } from '../../components/cards/frame/cards-frame';
import { useDispatch, useSelector } from 'react-redux';
import { viewClasseData } from '../../redux/classe/actionCreator';
import { addInscriptionData } from '../../redux/inscription/actionCreator';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getItem } from '../../utility/localStorageControl';
import { viewAutreFraisData } from '../../redux/autreFrais/actionCreator';

function InscriptionForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fileName, setFileName] = useState();
  const [selectedTranches, setSelectedTranches] = useState([]);

  useEffect(() => {
    dispatch(viewClasseData());
    dispatch(viewAutreFraisData());
  }, []);
  const { tranches, classes, loading } = useSelector((state) => {
    return {
      classes: state.ClasseReducer.arrayData,
      loading: state.InscriptionReducer.loading,
      tranches: state.AutreFraisReducer.data,
    };
  });

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const [form] = Form.useForm();

  const submitSuccess = () => {
    navigate(-1);
  };

  const submit = (values) => {
    values.dateNaiss = values.dateNaiss.format('YYYY-MM-DD');
    values.sexe = sexe;
    values.autre_frais = selectedTranches;
    values.club_id = [];
    if (fileName) {
      values.image = fileName;
    }
    console.log(values);

    dispatch(addInscriptionData(values, submitSuccess));
  };

  const [niveau, setNiveau] = useState();
  const [sexe, setSexe] = useState();
  const [classesSelect, setClassesSelect] = useState();
  const [Parent2, setParent2] = useState(false);

  useEffect(() => {
    const data = classes.filter((item) => item.niveau == niveau);
    const selectClasseData = data.map((item) => {
      return { value: item.id, label: item.titre };
    });
    setClassesSelect(selectClasseData);
  }, [niveau]);
  const handleAnnuler = () => {
    navigate(-1);
  };

  const props = {
    name: 'file',
    multiple: false,
    listType: 'picture',
    customRequest({ file, onSuccess, onError }) {
      const formData = new FormData();
      formData.append('file', file);
      axios
        .post('https://edulinkbackend.edulink.tn/api/image/upload', formData, {
          headers: {
            Authorization: `Bearer ${getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          onSuccess(response.data, file);

          setFileName(response.data.payload);
        });
    },
    beforeUpload(file) {
      return true;
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <>
      <Main>
        <Row gutter={25}>
          <Col xs={24}>
            <FormValidationWrap>
              <VerticalFormStyleWrap>
                <Cards headless>
                  <Form
                    name="sDash_validation-form"
                    form={form}
                    layout="vertical"
                    onFinish={submit}
                    autoComplete="off"
                    initialValues={{
                      autre: [], // Default value for the "autre" field
                    }}
                  >
                    <div className="form-title">Ajouter un Élève</div>
                    <Row gutter={30}>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="prenom"
                          label="Prénom Élève"
                          rules={[{ required: true, message: "Le prénom d'élève est obligatoire !" }]}
                        >
                          <Input placeholder="Prénom élève" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="nom"
                          label="Nom Élève"
                          rules={[{ required: true, message: "Le nom d'élève est obligatoire !" }]}
                        >
                          <Input placeholder="Nom élève" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="dateNaiss"
                          label="Date De Naissance"
                          rules={[{ required: true, message: 'Date est obligatoire!' }]}
                        >
                          <DatePicker placeholder="Date De Naissance" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item label="Sexe" rules={[{ required: true, message: 'Sexe est obligatoire!' }]}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Sexe"
                            onChange={(e) => setSexe(e)}
                            options={[
                              { value: 'male', label: 'garçon' },
                              { value: 'female', label: 'fille' },
                            ]}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={12} xs={24}>
                        <Form.Item
                          name="prenomM"
                          label="Prénom Parent"
                          rules={[{ required: true, message: 'Le prénom du parent est obligatoire !' }]}
                        >
                          <Input placeholder="Prénom Parent" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="nomM"
                          label="Nom Parent"
                          rules={[{ required: true, message: 'Le nom du parent est obligatoire !' }]}
                        >
                          <Input placeholder="Nom Parent" />
                        </Form.Item>
                      </Col>

                      <Col md={12} xs={24}>
                        <Form.Item
                          name="numM"
                          label="Téléphone Parent"
                          rules={[{ required: true, message: 'Téléphone du parent est obligatoire!' }]}
                        >
                          <Input placeholder="Téléphone Parent" />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item name="email" label="E-mail Parent">
                          <Input placeholder="E-mail Parent" />
                        </Form.Item>
                      </Col>

                      <Col md={24} xs={24}>
                        <div className="switch-container">
                          <span className="label">Parent 2</span>{' '}
                          <Switch
                            defaultChecked={false}
                            value={Parent2}
                            onChange={(e) => {
                              setParent2(e);
                            }}
                            size="large"
                          />
                        </div>
                      </Col>

                      {Parent2 && (
                        <>
                          <Col md={12} xs={24}>
                            <Form.Item
                              name="prenomP"
                              label="Prénom Parent 2"
                              rules={[{ required: true, message: 'Le prénom du parent 2 est obligatoire !' }]}
                            >
                              <Input placeholder="Prénom Parent 2" />
                            </Form.Item>
                          </Col>
                          <Col md={12} xs={24}>
                            <Form.Item
                              name="nomP"
                              label="Nom Parent 2"
                              rules={[{ required: true, message: 'Le nom du parent 2 est obligatoire !' }]}
                            >
                              <Input placeholder="Nom Parent 2" />
                            </Form.Item>
                          </Col>

                          <Col md={24} xs={24}>
                            <Form.Item
                              name="numP"
                              label="Téléphone Parent 2"
                              rules={[{ required: true, message: 'Téléphone du parent 2 est obligatoire!' }]}
                            >
                              <Input placeholder="Téléphone Parent 2" />
                            </Form.Item>
                          </Col>
                        </>
                      )}

                      <Col md={12} xs={24}>
                        <Form.Item label="Niveau" rules={[{ required: true, message: 'Niveau est obligatoire!' }]}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Niveau"
                            onChange={(e) => setNiveau(e)}
                            options={[
                              { value: '1', label: '1ère année' },
                              { value: '2', label: '2ème année' },
                              { value: '3', label: '3ème année' },
                              { value: '4', label: '4ème année' },
                              { value: '5', label: '5ème année' },
                              { value: '6', label: '6ème année' },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="classe_id"
                          label="Classe"
                          rules={[{ required: true, message: 'Classe est obligatoire!' }]}
                        >
                          <Select style={{ width: '100%' }} placeholder="Classe" options={classesSelect} />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item name="reduction" label="Reduction">
                          <Input placeholder="Reduction" />
                        </Form.Item>
                      </Col>

                      <Col md={12} xs={24}>
                        <Form.Item name="autre_frais" label="Autre Frais">
                          <Select
                            mode="multiple"
                            onChange={(e) => setSelectedTranches(e)}
                            options={tranches.map((item) => {
                              return { value: item.id, label: item.nom };
                            })}
                            placeholder="Choisir autre tranche "
                          />{' '}
                        </Form.Item>
                      </Col>
                      <Col md={24} xs={24}>
                        <Form.Item valuePropName="fileList" getValueFromEvent={normFile} label="Photo d'élève">
                          <Upload.Dragger maxCount={1} name="files" {...props}>
                            <p className="ant-upload-hint">sélectionner ou faire glisser des fichiers ici</p>
                            <p className="ant-upload-text">JPG, PNG or PDF, file size pas plus que 10MB</p>
                          </Upload.Dragger>
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="edulink-form-action mt-20">
                      <Button htmlType="reset" type="light" size="large" onClick={handleAnnuler}>
                        Annuler
                      </Button>
                      {loading ? (
                        <Button type="danger" size="large">
                          Chargement...
                        </Button>
                      ) : (
                        <Button htmlType="submit" type="danger" size="large">
                          Ajouter
                        </Button>
                      )}
                    </div>
                  </Form>
                </Cards>
              </VerticalFormStyleWrap>
            </FormValidationWrap>
          </Col>
        </Row>
      </Main>
    </>
  );
}

export default InscriptionForm;
