import React, { useState, lazy, Suspense, useEffect } from 'react';
import { Row, Col, Spin, Menu } from 'antd';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import { NoteNav, NoteWrapper } from './style';
import { Main } from '../styled';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import { MailOutlined } from '@ant-design/icons';
import DevoirForm from './DevoirForm';
import { useDispatch, useSelector } from 'react-redux';
import { viewMatiereByLevelData } from '../../redux/matiere/actionCreator';

const { SubMenu } = Menu;

const All = lazy(() => import('./overview/all'));

function Devoirs() {
  const [selectedMatiere, setSelectedMatiere] = useState(null);
  const dispatch = useDispatch();
  const { matieres } = useSelector((state) => {
    return {
      matieres: state.MatiereReducer.arrayDataLevel,
    };
  });
  useEffect(() => {
    dispatch(viewMatiereByLevelData());
  }, []);

  const [open, setOpen] = useState(false);
  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    dispatch(viewMatiereByLevelData());
  };

  return (
    <>
      <Main>
        <DevoirForm open={open} closeModal={closeModal} title="Ajouter un devoir" />
        <NoteWrapper>
          <Row className="justify-content-center" gutter={25}>
            <Col className="trigger-col" xxl={5} xl={7} lg={9} xs={24}>
              <div className="sidebar-card">
                <Cards headless>
                  <div className="note-sidebar-top">
                    <Button type="danger" size="default" block style={{ borderRadius: '17px' }} onClick={openModal}>
                      <UilPlus /> Ajouter Un Devoir
                    </Button>
                  </div>
                  <div className="note-sidebar-bottom">
                    <NoteNav>
                      <Menu mode="inline">
                        {[1, 2, 3, 4, 5, 6].map((niveau) => {
                          const matiereList = matieres.find((item) => item.niveau == niveau)?.matiere || [];

                          return (
                            <SubMenu
                              key={niveau}
                              title={
                                <span>
                                  <MailOutlined />
                                  <span>Niveau {niveau}</span>
                                </span>
                              }
                            >
                              {matiereList.map((item) => (
                                <Menu.Item
                                  key={niveau + item.nom}
                                  onClick={() => setSelectedMatiere({ niveau, id: item.id })}
                                >
                                  {item.nom}
                                </Menu.Item>
                              ))}
                            </SubMenu>
                          );
                        })}
                      </Menu>
                    </NoteNav>
                  </div>
                </Cards>
              </div>
            </Col>
            <Col xxl={19} xl={17} lg={15} xs={24}>
              <Suspense
                fallback={
                  <div className="spin">
                    <Spin />
                  </div>
                }
              >
                <All selectedMatiere={selectedMatiere} />
              </Suspense>
            </Col>
          </Row>
        </NoteWrapper>
      </Main>
    </>
  );
}

export default Devoirs;
