import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  addChargeBegin,
  addChargeSuccess,
  addChargeErr,
  updateChargeBegin,
  updateChargeSuccess,
  updateChargeErr,
  viewChargeBegin,
  viewChargeSuccess,
  viewChargeErr,
  viewSingleChargeBegin,
  viewSingleChargeSuccess,
  viewSingleChargeErr,
  deleteChargeBegin,
  deleteChargeSuccess,
  deleteChargeErr,
} = actions;

const viewChargeData = () => {
  return async (dispatch) => {
    dispatch(viewChargeBegin());
    try {
      const response = await DataService.get('/charge/index');
      if (response.status === 200) {
        dispatch(viewChargeSuccess(response.data.payload));
      } else {
        dispatch(viewChargeErr('err'));
      }
    } catch (err) {
      dispatch(viewChargeErr('err'));
    }
  };
};

const viewSingleChargeData = (id) => {
  return async (dispatch) => {
    dispatch(viewSingleChargeBegin());
    try {
      const response = await DataService.get(`/charge/singleInfo/${id}`);

      if (response.status === 200) {
        dispatch(viewSingleChargeSuccess(response.data.payload));
      } else {
        dispatch(viewSingleChargeErr('err'));
      }
    } catch (err) {
      dispatch(viewSingleChargeErr('err'));
    }
  };
};

const addChargeData = (data, callback) => {
  return async (dispatch) => {
    dispatch(addChargeBegin());
    try {
      const response = await DataService.post('/charge/add', data);

      if (response.status === 200) {
        dispatch(addChargeSuccess(response.data.payload));
        callback();
      
        message.success(response.data.message);
      } else {
        dispatch(addChargeErr('err'));
      }
    } catch (err) {
      dispatch(addChargeErr('err'));
    }
  };
};
const deleteChargeData = (id) => {
  return async (dispatch) => {
    dispatch(deleteChargeBegin());
    try {
      const response = await DataService.delete(`/charge/delete/${id}`);
      if (response.status === 200) {
        dispatch(deleteChargeSuccess(id));
        message.success(response.data.message);
      } else {
        dispatch(deleteChargeErr('err'));
      }
    } catch (err) {
      dispatch(deleteChargeErr('err'));
    }
  };
};
const updateChargeData = (id, data, callback) => {
  return async (dispatch) => {
    dispatch(updateChargeBegin());
    try {
      const response = await DataService.put(`/charge/updated/${id}`, data);

      if (response.status === 200) {
        dispatch(updateChargeSuccess(response.data.payload));
        callback();
        message.success(response.data.message);
      } else {
        dispatch(updateChargeErr('err'));
      }
    } catch (err) {
      dispatch(updateChargeErr('err'));
    }
  };
};

export { viewChargeData, viewSingleChargeData, updateChargeData, deleteChargeData, addChargeData };
