import { Button, Card, Col, Form, Input, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { CourseCardWrap } from './Style';
import { useDispatch } from 'react-redux';
import { addPaimentData } from '../../redux/paiment/actionCreator';
import { Badge } from '../../container/pages/style';

function AttachedEventCard({ data, id }) {
  const dispatch = useDispatch();
  const [paimentForm] = Form.useForm();

  const [isPaimentOpen, setIsPaimentOpen] = useState(false);

  const handlePaimentOpen = () => {
    if (data.paid < data.frais) {
      setIsPaimentOpen(true);
    }
  };
  const handlePaimentClose = () => {
    paimentForm.resetFields();
    setIsPaimentOpen(false);
  };

  const addPaiment = (values) => {
    values.etudiant_id = id;
    values.event_id = data.id;
    dispatch(addPaimentData(values, handlePaimentClose));
  };

  return (
    <Col xxl={8} lg={12} sm={12} xs={24}>
      <Modal open={isPaimentOpen} onCancel={handlePaimentClose} footer={null} title={`Evennement : ${data.titre}`}>
        <Row gutter={25}>
          <Form form={paimentForm} onFinish={addPaiment}>
            <Form.Item name="payer">
              <Input placeholder="Saisir le montant a payer" style={{ marginLeft: '10px', color: 'black' }} />
            </Form.Item>

            <div className="add-event-footer text-right" style={{ width: '480px' }}>
              <Button htmlType="submit" className="btn-save" type="primary">
                payer
              </Button>
            </div>
          </Form>
        </Row>
      </Modal>
      <CourseCardWrap className="edulink-course-card-single">
        <Card bordered={false} onClick={handlePaimentOpen}>
          <div className="edulink-course-card-thumbnail">
            <img
              src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${data.image}`}
              style={{ borderRadius: '10px', height: '180px', width: '100%', objectFit: 'cover' }}
            />
          </div>
          <div className="edulink-course-card-content">
            <div className="edulink-course-card-meta">
              <div className="edulink-course-card-meta__left">
                <span className="edulink-course-card-meta__pricing" style={{ color: 'black' }}>
                  {data.titre} <br />
                  <Badge
                    className="pricing-badge"
                    type={
                      data.frais == 0
                        ? 'success'
                        : data.paid
                        ? data.paid == data.frais
                          ? 'success'
                          : 'warning'
                        : 'danger'
                    }
                  >
                    {data.paid ? data.paid : '0'}/{data.frais} DT
                  </Badge>
                </span>
              </div>
            </div>
          </div>
        </Card>
      </CourseCardWrap>
    </Col>
  );
}

export default AttachedEventCard;
