import { message } from 'antd';
import actions from './actions';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const {
  VIEW_FRAIS_BEGIN,
  VIEW_FRAIS_SUCCESS,
  VIEW_FRAIS_ERR,

  ADD_FRAIS_BEGIN,
  ADD_FRAIS_SUCCESS,
  ADD_FRAIS_ERR,

  DELETE_FRAIS_BEGIN,
  DELETE_FRAIS_SUCCESS,
  DELETE_FRAIS_ERR,

  UPDATE_FRAIS_BEGIN,
  UPDATE_FRAIS_SUCCESS,
  UPDATE_FRAIS_ERR,
} = actions;

const FraisReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_FRAIS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_FRAIS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_FRAIS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case VIEW_FRAIS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_FRAIS_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case VIEW_FRAIS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case DELETE_FRAIS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_FRAIS_SUCCESS:
      const arrayData = state.data.filter((item) => item.id !== data);
      return {
        ...state,
        data: arrayData,
        loading: false,
      };
    case DELETE_FRAIS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ADD_FRAIS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_FRAIS_SUCCESS:
      return {
        ...state,
        data: [...state.data, data],
        loading: false,
      };
    case ADD_FRAIS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default FraisReducer;
